import { FC, PropsWithChildren } from "react";
// import { ReactLocationDevtools } from 'react-location-devtools';
import { Router } from 'react-location';
import filterRoutes from "./filter";
import location from "./location";
import routes from "./routes";
// import { currentConfigVar } from "apollo/cache/config";

const RoutesProvider: FC<PropsWithChildren> = ({ children }) => {
  // const currentTheme = useReactiveVar(currentConfigVar);
  // const currentUser = useReactiveVar(currentUserVar);

  return (
    <div className={"light"}>
      <Router location={location} routes={routes} filterRoutes={filterRoutes()}>
        {/* <ReactLocationDevtools initialIsOpen={false} /> */}
        {children}
      </Router>
    </div>
  )
}

export default RoutesProvider;