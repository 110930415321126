import { useQuery } from "@apollo/client";
import { LocationGenerics } from "router/location";
import { Header, Loader } from "components/layouts";
import { FC } from "react";
import { useMatch } from "react-location";
import DetailsContainer from "./details";
import MembersContainer from "./members";
import { GET_COMPLAINT } from "./queries";
import ResolutionContainer from "./resolution";
import TimelineContainer from "./timeline";
import FlowContainer from "./flow";

export const ComplaintPage: FC = () => {
  const { params } = useMatch<LocationGenerics>();
  const { data, loading, refetch } = useQuery(GET_COMPLAINT, {
    variables: {
      id: params.complaint,
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "network-only",
  });

  return (
    <main className="flex-1 flex flex-col overflow-hidden bg-gray-100">
      <Header />
      <div className="flex flex-1 overflow-hidden">
        {loading ? (
          <Loader />
        ) : (
          <div className="flex-1 grid gap-x-6 grid-cols-5">
            <div className="flex flex-col space-y-6 col-span-3 overflow-y-auto no-scrollbar py-6 pl-6">
              {/* Description list*/}

              <DetailsContainer complaint={data?.complaint} />
              <ResolutionContainer complaint={data?.complaint} refetch={refetch} />
            </div>
            <div className="flex flex-col space-y-6 col-span-2 overflow-y-auto no-scrollbar py-6 pr-6">
              {data?.complaint?.status !== "Closed" && (
                <FlowContainer complaint={data?.complaint} refetch={refetch} />
              )}
              <TimelineContainer complaint={data?.complaint} />
              <MembersContainer complaint={data?.complaint} />
            </div>
          </div>
        )}
      </div>
    </main>
  );
}

export default ComplaintPage