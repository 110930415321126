import location from "router/location";
import { Action } from "components/buttons/action-button";

export const dispatchModal =
  (id: string | undefined, action?: Exclude<Action, "expand" | "goto" | "clone">) => () => {
    const next = location.buildNext("/", {
      search: (old) => ({
        ...old,
        id,
        modal: action,
      }),
      from: location.current,
    });
    location.navigate(next, false);
  };

export const switchModal =
  (action: Exclude<Action, "expand" | "goto" | "clone">) => () => {
    const next = location.buildNext("/", {
      search: (old) => ({
        ...old,
        modal: action,
      }),
      from: location.current,
    });
    location.navigate(next, false);
  };

export const closeModal = (): void => {
  const next = location.buildNext("/", {
    search: (old) => ({
      ...old,
      id: undefined,
      modal: undefined,
    }),
    from: location.current,
  });
  location.navigate(next, false);
};
