import { gql, useQuery } from "@apollo/client";
import { Disclosure, DisclosurePanel, DisclosureButton } from "@headlessui/react";
import {
  BoltIcon,
  ChatBubbleLeftRightIcon,
  ChatBubbleOvalLeftIcon,
  DevicePhoneMobileIcon,
} from "@heroicons/react/24/outline";
import { Loader, StatusBadge } from "components";
import parse from "html-react-parser";
import lodash from "lodash";
import moment from "moment";
import { useNavigate } from "react-location";
import { classNames, displayUserName, wrapClick } from "utils";

const GET_COMPLAINT_HISTORY = gql`
  query GetServicePointComplaints(
    $servicePoint: ID
    $page: Int
    $pageSize: Int
    $sort: String
  ) {
    complaints: getComplaints(
      servicePoint: $servicePoint
      page: $page
      pageSize: $pageSize
      sort: $sort
    ) {
      _id
      code
      description
      createdBy {
        ... on User {
          _id
          code
          firstName
          lastName
          profileImageUrl
        }
        ... on PartnerUser {
          _id
          code
          firstName
          lastName
          profileImageUrl
        }
      }
      contactPerson {
        fullName
        emailAddress
        phoneNumber
      }
      category
      type {
        _id
        code
        name
      }
      status
      createdAt
    }
  }
`;

interface ServicePointComplaintHistoryContainerProps {
  servicePoint: string;
}

export const ServicePointComplaintHistoryContainer = ({
  servicePoint,
}: ServicePointComplaintHistoryContainerProps) => {
  const { loading, data } = useQuery(GET_COMPLAINT_HISTORY, {
    notifyOnNetworkStatusChange: false,
    variables: {
      servicePoint,
      sort: "-createdAt",
      page: 1,
      pageSize: 10,
    },
  });

  const actionIconMap = {
    General: (
      <span
        className={classNames(
          "bg-green-500",
          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white",
        )}
      >
        <DevicePhoneMobileIcon
          className="h-5 w-5 text-white"
          aria-hidden="true"
        />
      </span>
    ),
    Commercial: (
      <span
        className={classNames(
          "bg-green-500",
          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white",
        )}
      >
        <DevicePhoneMobileIcon
          className="h-5 w-5 text-white"
          aria-hidden="true"
        />
      </span>
    ),
    Technical: (
      <span
        className={classNames(
          "bg-green-500",
          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white",
        )}
      >
        <ChatBubbleOvalLeftIcon
          className="h-5 w-5 text-white"
          aria-hidden="true"
        />
      </span>
    ),
    CustomerService: (
      <span
        className={classNames(
          "bg-green-500",
          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white",
        )}
      >
        <BoltIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    RevenueProtection: (
      <span
        className={classNames(
          "bg-green-500",
          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white",
        )}
      >
        <ChatBubbleLeftRightIcon
          className="h-5 w-5 text-white"
          aria-hidden="true"
        />
      </span>
    ),
    Operations: (
      <span
        className={classNames(
          "bg-green-500",
          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white",
        )}
      >
        <ChatBubbleLeftRightIcon
          className="h-5 w-5 text-white"
          aria-hidden="true"
        />
      </span>
    ),
    Geospatial: (
      <span
        className={classNames(
          "bg-green-500",
          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white",
        )}
      >
        <ChatBubbleLeftRightIcon
          className="h-5 w-5 text-white"
          aria-hidden="true"
        />
      </span>
    ),
  };

  if (loading) {
    <div className="flex min-h-[65vh] items-center justify-center">
      <Loader />
    </div>;
  }

  return data?.complaints?.length > 0 ? (
    <div className="flow-root  min-h-[65vh]">
      <ul className="-mb-8">
        {data?.complaints?.map((historyItem: any, historyItemIdx: number) => (
          <li key={historyItemIdx}>
            <div className="relative pb-8">
              {historyItemIdx !== data?.complaints.length - 1 ? (
                <span
                  className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                  aria-hidden="true"
                />
              ) : null}
              <div className="relative flex space-x-3">
                <div>
                  {lodash.get(actionIconMap, historyItem?.category, "")}
                </div>
                <div className="flex min-w-0 flex-1 justify-between space-x-12 pt-1.5">
                  <div className="flex-1">
                    <HistoryDetails history={historyItem} />
                  </div>
                  <div className="whitespace-nowrap text-right text-sm flex flex-col text-gray-500">
                    <time dateTime={historyItem?.createdAt}>
                      {moment(historyItem?.createdAt).format("DD/MM/YYYY")}
                    </time>
                    <time dateTime={historyItem?.createdAt}>
                      {moment(historyItem?.createdAt).format("hh:mm A")}
                    </time>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  ) : (
    <div className="flex min-h-[65vh] items-center justify-center">
      <div className="text-center">
        <svg
          className="mx-auto h-12 w-12 text-gray-400 dark:text-gray-300"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
          />
        </svg>
        <h3 className="mt-2 text-sm font-medium text-gray-900 dark:text-gray-100">
          No Tickets Data Yet
        </h3>
        <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
          Looks like our services are satisfactory to customer.
        </p>
      </div>
    </div>
  );
};

const HistoryDetails = ({ history }: { history: any }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="text-sm text-gray-500">
        <span className="font-medium text-gray-900">
          {history?.createdBy
            ? displayUserName(history?.createdBy)
            : history?.contactPerson?.fullName}
        </span>{" "}
        submitted a{" "}
        <span className="font-medium text-gray-900">
          {lodash.startCase(history.category)}
        </span>{" "}
        complaint
        <Disclosure
          defaultOpen
          as="div"
          onClick={wrapClick(() => navigate({
            to: `/tickets/${history._id}`
          }))}
          className="relative p-4 group border border-dashed grid-cols-4 border-gray-300 rounded mt-6 hover:bg-primary-50 cursor-pointer"
        >
          <DisclosureButton className="absolute bg-gray-50 rounded-md group-hover:bg-primary-50 px-2 -top-2 text-xs">
            Details
          </DisclosureButton>
          <DisclosurePanel className="grid mt-1 grid-cols-2 gap-4">
            <div className="col-span-2">
              <div className="grid grid-cols-4 gap-4">
                <div>
                  <span className="block text-xs font-light">
                    Ticket Number
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {history?.code || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-xs font-light">Ticket Date</span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {history?.createdAt
                      ? moment(history.createdAt).format("DD/MM/YYYY hh:mm A")
                      : "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-xs font-light">
                    Ticket Status
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    <StatusBadge
                      status={history?.status}
                      options={{
                        Open: "text-yellow-700 bg-yellow-50 ring-yellow-600/20",
                        InProgress: "text-blue-600 bg-blue-50 ring-blue-500/10",
                        Escalated: "text-red-800 bg-red-50 ring-red-600/20",
                        Closed: "text-green-800 bg-green-50 ring-green-600/20",
                      }}
                    />
                  </div>
                </div>
                <div>
                  <span className="block text-xs font-light">Ticket Type</span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {history?.type?.name || "N/A"}
                  </div>
                </div>
              </div>
            </div>
          </DisclosurePanel>
        </Disclosure>
      </div>
      {history?.description && (
        <div className="relative p-4 border border-gray-300 rounded mt-6">
          <span className="absolute bg-white px-2 -top-2 text-xs">
            Description
          </span>
          <p className="text-sm text-gray-600 font-light">
            {parse(history.description)}
          </p>
        </div>
      )}
    </>
  );
};
