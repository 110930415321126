/* eslint-disable no-template-curly-in-string */
import {
  ChatBubbleOvalLeftEllipsisIcon,
  ClipboardDocumentCheckIcon,
  ClipboardDocumentListIcon,
  CurrencyDollarIcon,
  PlayCircleIcon,
  PlayIcon,
  PlusCircleIcon,
  ReceiptPercentIcon,
  StopCircleIcon,
  UserCircleIcon
} from "@heroicons/react/24/solid";
import parse from "html-react-parser";
import lodash from "lodash";
import moment from "moment";
import { FC, useMemo } from "react";
import { classNames, displayUserName } from "utils";
import { Complaint } from "./schema";

export const actionsMap = {
  Start: "started working on ticket",
  Escalate: "escalated ticket to",
  ExtraWork: "completed resolution on Extra",
  ExtraWorkNo: "completed resolution on Extra No",
  HubtelWork:
    'started resolution on <span className="font-medium text-gray-900">Hubtel</span>',
  HubtelWorkDone:
    'completed resolution on <span className="font-medium text-gray-900">Hubtel</span>',
  AdoraWork:
    'started resolution on <span className="font-medium text-gray-900">Adora</span>',
  AdoraWorkDone:
    'completed resolution on<span className="font-medium text-gray-900">Adora</span>',
  ZeusWork:
    'initiated <span className="font-medium text-gray-900">${_.startCase(serviceOrderType)}</span> on ZEUS',
  ServiceOrder:
    'completed <span className="font-medium text-gray-900">${_.startCase(serviceOrderType)}</span> on ZEUS',
  Completed: "closed ticket",
  Escalated: "escalated ticket to",
  Notes: "add a note",
};

const iconsMap = {
  Start: PlayCircleIcon,
  Escalate: UserCircleIcon,
  ExtraWork: PlayIcon,
  ExtraWorkNo: PlayIcon,
  HubtelWork: CurrencyDollarIcon,
  HubtelWorkDone: CurrencyDollarIcon,
  AdoraWork: ReceiptPercentIcon,
  AdoraWorkDone: ReceiptPercentIcon,
  ZeusWork: ClipboardDocumentListIcon,
  ServiceOrder: ClipboardDocumentCheckIcon,
  Completed: StopCircleIcon,
  Escalated: UserCircleIcon,
  Notes: ChatBubbleOvalLeftEllipsisIcon,
};

const iconBackgroundsMap = {
  Start: "bg-green-500",
  Escalate: "bg-gray-400",
  ExtraWork: "bg-red-500",
  ExtraWorkNo: "bg-red-500",
  HubtelWork: "bg-yellow-400",
  HubtelWorkDone: "bg-yellow-500",
  AdoraWork: "bg-red-400",
  AdoraWorkDone: "bg-red-500",
  ZeusWork: "bg-blue-400",
  ServiceOrder: "bg-blue-500",
  Completed: "bg-green-500",
  Escalated: "bg-gray-400",
  Notes: "bg-gray-500",
};

interface TimelineContainerProps {
  complaint: Complaint;
}

const TimelineContainer: FC<TimelineContainerProps> = ({ complaint }) => {
  const timeline = useMemo(
    () =>
      lodash.reject(complaint?.stages, ({ stage }) =>
        lodash.includes(["Escalate", "ExtraWork"], stage),
      ),
    [complaint?.stages],
  );

  return (
    <section aria-labelledby="timeline-title">
      <div className="bg-white py-6 shadow sm:rounded-lg">
        <h2
          id="timeline-title"
          className="text-lg font-medium text-gray-900 px-6"
        >
          Timeline
        </h2>

        {/* Activity Feed */}
        <div className="mt-6 flow-root max-h-96 overflow-y-auto px-6">
          <ul className="-mb-8">
            <li>
              <div className="relative pb-8">
                {timeline.length ? (
                  <span
                    aria-hidden="true"
                    className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                  />
                ) : null}
                <div className="relative flex space-x-3">
                  <div>
                    <span
                      className={classNames(
                        "bg-gray-400",
                        "flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white",
                      )}
                    >
                      <PlusCircleIcon
                        aria-hidden="true"
                        className="h-5 w-5 text-white"
                      />
                    </span>
                  </div>
                  <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                    <div>
                      <p className="text-sm text-gray-500">
                        Ticket was generated
                      </p>
                    </div>
                    <div className="whitespace-nowrap text-right text-sm text-gray-500">
                      <time dateTime={complaint?.createdAt}>
                        {moment(complaint?.createdAt).calendar({
                          lastDay: "[Yesterday at] hh:mm A",
                          sameDay: "[Today at] hh:mm A",
                          nextDay: "[Tomorrow at] hh:mm A",
                          lastWeek: "DD/MM/YYYY hh:mm A",
                          nextWeek: "dddd [at] hh:mm A",
                          sameElse: "DD/MM/YYYY hh:mm A",
                        })}
                      </time>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            {timeline?.map((item, itemIdx) => {
              const Icon = iconsMap[item.stage];
              return (
                <li key={item._id}>
                  <div className="relative pb-8">
                    {itemIdx !== timeline.length - 1 ? (
                      <span
                        aria-hidden="true"
                        className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                      />
                    ) : null}
                    <div className="relative flex space-x-3">
                      <div>
                        <span
                          className={classNames(
                            iconBackgroundsMap[item.stage],
                            "flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white",
                          )}
                        >
                          <Icon
                            aria-hidden="true"
                            className="h-5 w-5 text-white"
                          />
                        </span>
                      </div>
                      <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                        <div>
                          <p className="text-sm text-gray-500">
                            <span className="font-medium text-gray-900">
                              {displayUserName(item.user)}
                            </span>{" "}
                            {parse(
                              lodash.template(actionsMap[item.stage])(item),
                            )}{" "}
                            {item?.escalatedTo && (
                              <span className="font-medium text-gray-900">
                                {displayUserName(item.escalatedTo)}
                              </span>
                            )}
                          </p>
                        </div>
                        <div className="whitespace-nowrap text-right text-sm text-gray-500">
                          <time dateTime={item.timestamp}>
                            {moment(item.timestamp).calendar({
                              lastDay: "[Yesterday at] hh:mm A",
                              sameDay: "[Today at] hh:mm A",
                              nextDay: "[Tomorrow at] hh:mm A",
                              lastWeek: "DD/MM/YYYY hh:mm A",
                              nextWeek: "dddd [at] hh:mm A",
                              sameElse: "DD/MM/YYYY hh:mm A",
                            })}
                          </time>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default TimelineContainer;
