import { gql } from "@apollo/client";

export const GET_COMPLAINT = gql`
  query GetComplaint($id: ID!) {
    complaint: getComplaint(id: $id) {
      _id
      code
      userRole {
        _id
        code
        name
      }
      category
      type {
        _id
        code
        name
      }
      contactPerson {
        fullName
        emailAddress
        phoneNumber
      }
      description
      supportingDocumentUrls
      servicePoint {
        _id
      }
      history {
        actor {
          ... on User {
            _id
            code
            lastName
            firstName
            phoneNumber
            emailAddress
            profileImageUrl
            roleCode
            roleName
          }
          ... on PartnerUser {
            _id
            code
            lastName
            firstName
            phoneNumber
            emailAddress
            profileImageUrl
            role
          }
        }
        actorType
        action
        assignee {
          ... on User {
            _id
            code
            lastName
            firstName
            phoneNumber
            emailAddress
            profileImageUrl
            roleCode
            roleName
          }
          ... on PartnerUser {
            _id
            code
            lastName
            firstName
            phoneNumber
            emailAddress
            profileImageUrl
            role
          }
        }
        assigneeType
        timestamp
        notes
      }
      status
      meta {
        complaintLocation {
          type
          coordinates
        }
        complaintLocationName
        complaintImageLocation {
          type
          coordinates
        }
        complaintImageLocationName
        complaintRecordedAt
        complaintSyncedAt
      }
      createdAt
      updatedAt
      startedAt
      closedAt
      stages {
        user {
          ... on User {
            _id
            code
            lastName
            firstName
            phoneNumber
            emailAddress
            profileImageUrl
            roleCode
            roleName
          }
          ... on PartnerUser {
            _id
            code
            lastName
            firstName
            phoneNumber
            emailAddress
            profileImageUrl
            role
          }
        }
        userType
        stage
        serviceOrder {
          ... on ReplacementServiceOrder {
            _id
            code
            location {
              address
              community
            }
            replacementStatus: status
            updatedAt
          }
          ... on InvestigationServiceOrder {
            _id
            code
            location {
              address
              community
            }
            investigationStatus: status
            updatedAt
          }
          ... on TerminationServiceOrder {
            _id
            code
            location {
              address
              community
            }
            terminationStatus: status
            updatedAt
          }
          ... on FaultServiceOrder {
            _id
            code
            location {
              address
              community
            }
            faultStatus: status
            updatedAt
          }
          ... on DisconnectionServiceOrder {
            _id
            code
            location {
              address
              community
            }
            disconnectionStatus: status
            updatedAt
          }
          ... on ReconnectionServiceOrder {
            _id
            code
            location {
              address
              community
            }
            reconnectionStatus: status
            updatedAt
          }
        }
        serviceOrderType
        notes
        supportingDocumentUrls
        escalatedTo {
          _id
          code
          lastName
          firstName
          phoneNumber
          emailAddress
          profileImageUrl
          roleCode
          roleName
        }
        timestamp
      }
      currentStage {
        stage
        user {
          ... on User {
            _id
            code
            lastName
            firstName
            phoneNumber
            emailAddress
            profileImageUrl
            roleCode
            roleName
          }
          ... on PartnerUser {
            _id
            code
            lastName
            firstName
            phoneNumber
            emailAddress
            profileImageUrl
            role
          }
        }
        userType
        serviceOrder {
          ... on ReplacementServiceOrder {
            _id
            code
            location {
              address
              community
            }
            replacementStatus: status
            updatedAt
          }
          ... on InvestigationServiceOrder {
            _id
            code
            location {
              address
              community
            }
            investigationStatus: status
            updatedAt
          }
          ... on TerminationServiceOrder {
            _id
            code
            location {
              address
              community
            }
            terminationStatus: status
            updatedAt
          }
          ... on FaultServiceOrder {
            _id
            code
            location {
              address
              community
            }
            faultStatus: status
            updatedAt
          }
          ... on DisconnectionServiceOrder {
            _id
            code
            location {
              address
              community
            }
            disconnectionStatus: status
            updatedAt
          }
          ... on ReconnectionServiceOrder {
            _id
            code
            location {
              address
              community
            }
            reconnectionStatus: status
            updatedAt
          }
        }
        serviceOrderType
      }
      createdBy {
        ... on User {
          _id
          code
          lastName
          firstName
          phoneNumber
          emailAddress
          profileImageUrl
          roleCode
          roleName
        }
        ... on PartnerUser {
          _id
          code
          lastName
          firstName
          phoneNumber
          emailAddress
          profileImageUrl
          role
        }
      }
      createdByType
      members {
        _id
        code
        lastName
        firstName
        phoneNumber
        emailAddress
        profileImageUrl
        roleCode
        roleName
      }
      resolution {
        notes
        supportingDocumentUrls
      }
      estimatedResolutionDate
    }
  }
`;

export const CREATE_COMPLAINT_SERVICE_ORDER = gql`
  mutation CreateComplaintServiceOrder(
    $id: ID!
    $serviceOrderType: ComplaintServiceOrderType!
    $notes: String
  ) {
    createComplaintServiceOrder(
      id: $id
      serviceOrderType: $serviceOrderType
      notes: $notes
    ) {
      _id
    }
  }
`;

export const COMPLETE_COMPLAINT_SERVICE_ORDER = gql`
  mutation CompleteComplaintServiceOrder(
    $id: ID!
    $notes: String
    $supportingDocumentUrls: [String]
  ) {
    completeComplaintServiceOrder(
      id: $id
      notes: $notes
      supportingDocumentUrls: $supportingDocumentUrls
    ) {
      _id
    }
  }
`;

export const UPDATE_COMPLAINT_STAGE = gql`
  mutation UpdateComplaintStage(
    $id: ID!
    $stage: ComplaintStage!
    $notes: String
    $supportingDocumentUrls: [String]
  ) {
    updateComplaintStage(
      id: $id
      stage: $stage
      notes: $notes
      supportingDocumentUrls: $supportingDocumentUrls
    ) {
      _id
    }
  }
`;

export const ESCALATE_COMPLAINT = gql`
  mutation EscalateComplaint($id: ID!, $user: ID!, $notes: String) {
    escalateComplaint(id: $id, user: $user, notes: $notes) {
      _id
    }
  }
`;

export const CLOSE_COMPLAINT = gql`
  mutation CloseComplaint(
    $id: ID!
    $supportingDocumentUrls: [String]
    $notes: String
  ) {
    closeComplaint(
      id: $id
      supportingDocumentUrls: $supportingDocumentUrls
      notes: $notes
    ) {
      _id
    }
  }
`;

export const GET_COMPLAINT_USERS = gql`
  query GetComplaintUsers(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
  ) {
    users: getUsers(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
    ) {
      _id
      code
      lastName
      firstName
      phoneNumber
      emailAddress
      profileImageUrl
      roleCode
      roleName
      createdAt
      updatedAt
    }
  }
`;
