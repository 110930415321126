import { useQuery, useReactiveVar } from "@apollo/client";
import { TicketIcon } from "@heroicons/react/24/outline";
import { currentConfigVar } from "apollo/cache/config";
import { Shimmers, StatusBadge, TableComponent } from "components";
import config from "config";
import {
  ComplaintCategoryPicker,
  ComplaintTypePicker,
  DistrictPicker,
  RegionPicker,
} from "containers";
import lodash from "lodash";
import moment from "moment";
import numeral from "numeral";
import { FC, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import {
  classNames,
  dispatchModal,
  displayUserName,
  useTableData,
  useUrlState,
  wrapClick,
} from "utils";
import { GET_COMPLAINT_SUMMARY, GET_COMPLAINTS } from "./apollo";
import CreateTicketContainer from "./create";
import ViewComplaintContainer from "./view";

const generalNavigation = [
  { name: "All Tickets", href: "totalTickets" },
  { name: "Open Tickets", href: "totalOpenTickets" },
  { name: "In Progress Tickets", href: "totalInProgressTickets" },
  { name: "Closed Tickets", href: "totalClosedTickets" },
  { name: "Overdue Tickets", href: "totalOverdueTickets" },
];

const personalNavigation = [
  { name: "My Tickets", href: "userTickets" },
  { name: "My Open Tickets", href: "userOpenTickets" },
  { name: "My In Progress Tickets", href: "userInProgressTickets" },
  { name: "My Closed Tickets", href: "userClosedTickets" },
  { name: "My Overdue Tickets", href: "userOverdueTickets" },
];

const sectionHeaders = lodash
  .chain([...generalNavigation, ...personalNavigation])
  .keyBy("href")
  .mapValues((item) => item.name)
  .value();

const sectionFilters = (section?: string) => {
  switch (section) {
    case "totalTickets": {
      return {};
    }
    case "totalOpenTickets": {
      return { status: "Open" };
    }
    case "totalInProgressTickets": {
      return { status: "InProgress" };
    }
    case "totalClosedTickets": {
      return { status: "Closed" };
    }
    case "totalOverdueTickets": {
      return { statuses: ["Open", "InProgress"], overdue: true };
    }
    case "userTickets": {
      return { assignedToMe: true };
    }
    case "userOpenTickets": {
      return { status: "Open", assignedToMe: true };
    }
    case "userInProgressTickets": {
      return { status: "InProgress", assignedToMe: true };
    }
    case "userClosedTickets": {
      return { status: "Closed", assignedToMe: true };
    }
    case "userOverdueTickets": {
      return {
        statuses: ["Open", "InProgress"],
        assignedToMe: true,
        overdue: true,
      };
    }
    default: {
      return {};
    }
  }
};

const ComplaintsPage: FC = () => {
  const { dateFormat } = useReactiveVar(currentConfigVar);
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [category, setCategory] = useUrlState("category");
  const [modal, setModal] = useUrlState("modal");
  const [region, setRegion] = useUrlState("region");
  const [district, setDistrict] = useUrlState("district");
  const [type, setType] = useUrlState<string | undefined>("type");
  const [createOpen, setCreateOpen] = useState(false);

  const filter = useMemo(
    () => ({
      page: Number(searchParams.page || config.constants.page),
      pageSize: Number(searchParams.pageSize || config.constants.pageSize),
      search: searchParams.search || undefined,
      searchFields: [
        "code",
        "contactPerson.fullName",
        "contactPerson.phoneNumber",
      ],
      sort: searchParams.sort || "",
      fromDate: searchParams.fromDate || undefined,
      toDate: searchParams.toDate || undefined,
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      status: searchParams.status || undefined,
      type,
      ...sectionFilters(searchParams.section),
    }),
    [searchParams, type],
  );

  const { data, loading, refetch, networkStatus } = useQuery(GET_COMPLAINTS, {
    variables: filter,
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      void refetchSummary();
    },
  });

  const { data: dataSummary, refetch: refetchSummary } = useQuery(
    GET_COMPLAINT_SUMMARY,
    {
      variables: filter,
      notifyOnNetworkStatusChange: false,
    },
  );

  const records = useTableData(data);

  const onSearch = (searchValue: string) => {
    navigate({
      search: (old) => ({
        ...old,
        page: config.constants.page,
        pageSize: searchParams.pageSize || config.constants.pageSize,
        search: String(searchValue),
      }),
    });
  };

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? {} : { search: "" }),
        ...(!old?.sort ? { sort: "-createdAt" } : {}),
        ...(!old?.section ? { section: "totalTickets" } : {}),
      }),
    });
  }, [navigate]);

  return (
    <main className="flex-1 flex flex-row overflow-hidden bg-white divide-x divide-gray-200">
      <nav className="flex flex-col divide-y divide-gray-200 w-96   flex-shrink-0">
        <div className="h-20 px-6 flex justify-between items-center">
          <h3 className="text-lg font-medium text-gray-900">Tickets</h3>
        </div>
        <div className="p-6 space-y-6">
          <div className="">
            <div className="text-xs font-semibold leading-6 text-gray-400">
              General
            </div>
            <ul className="-mx-2 mt-2 space-y-1">
              {generalNavigation.map((item) => {
                const count = lodash.get(dataSummary?.summary, item.href, 0);
                return (
                  <li key={item.name}>
                    <Link
                      search={(old) => ({
                        ...old,
                        section: item.href,
                      })}
                      className={classNames(
                        searchParams?.section === item.href
                          ? "bg-primary-100 text-primary-600"
                          : "text-gray-700 hover:bg-gray-50 hover:text-primary-600",
                        "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6",
                      )}
                    >
                      {item.name}
                      {count ? (
                        <span
                          aria-hidden="true"
                          className="ml-auto w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200"
                        >
                          {numeral(count).format("0,0")}
                        </span>
                      ) : null}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="">
            <div className="text-xs font-semibold leading-6 text-gray-400">
              Personal
            </div>
            <ul className="-mx-2 mt-2 space-y-1">
              {personalNavigation.map((item) => {
                const count = lodash.get(dataSummary?.summary, item.href, 0);
                return (
                  <li key={item.name}>
                    <Link
                      search={(old) => ({
                        ...old,
                        section: item.href,
                      })}
                      className={classNames(
                        searchParams?.section === item.href
                          ? "bg-primary-100 text-primary-600"
                          : "text-gray-700 hover:bg-gray-50 hover:text-primary-600",
                        "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6",
                      )}
                    >
                      {item.name}
                      {count ? (
                        <span
                          aria-hidden="true"
                          className="ml-auto w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200"
                        >
                          {numeral(count).format("0,0")}
                        </span>
                      ) : null}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </nav>

      <div className="flex flex-1 flex-col overflow-hidden divide-y divide-gray-200">
        <div className="h-20 px-6 flex-shrink-0 flex justify-between items-center">
          <h3 className="text-lg font-medium text-gray-900">
            {sectionHeaders[searchParams.section as string]}
          </h3>

          <div className="">
            <button
              type="button"
              onClick={wrapClick(() => setCreateOpen(true))}
              className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-primary-50 hover:ring-primary-600 group"
            >
              <TicketIcon
                aria-hidden="true"
                className="-ml-0.5 h-5 w-5 text-gray-400 group-hover:text-primary-600"
              />
              Create General Ticket
            </button>
          </div>
        </div>
        <div className="flex flex-1 overflow-y-auto">
          <div className="flex-1 min-h-full mx-auto min-w-0 py-4 overflow-hidden overflow-y-auto light flex">
            <TableComponent
              title={"complaints"}
              refetch={refetch}
              isRefetching={loading && networkStatus === 4}
              loading={loading}
              data={records}
              hasSearch={true}
              onSearchClicked={onSearch}
              renderFilter={() => (
                <div className="grid grid-cols-5 gap-6 p-6 bg-primary-100/60 rounded-md my-3">
                  <div className="flex flex-col gap-1">
                    <RegionPicker
                      id="region"
                      label="Region"
                      setFieldTouched={() => {}}
                      setFieldError={() => {}}
                      setFieldValue={(_field: string, value: string) => {
                        setRegion(value || "");
                        setDistrict("");
                      }}
                      values={{ region }}
                      rawId={true}
                    />
                  </div>

                  <div className="flex flex-col gap-1">
                    <DistrictPicker
                      id="district"
                      label="District"
                      setFieldTouched={() => {}}
                      setFieldError={() => {}}
                      setFieldValue={(_field: string, value: string) =>
                        setDistrict(value || "")
                      }
                      values={{ district }}
                      filter={{ region }}
                      rawId={true}
                      disabled={!region}
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <ComplaintCategoryPicker
                      id={"category"}
                      values={{ category }}
                      label="Category"
                      rawId
                      setFieldValue={(_: any, value: string) => {
                        // console.log(value);
                        setCategory(value ?? undefined);
                        setType(undefined);
                      }}
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <ComplaintTypePicker
                      id={"type"}
                      label="Complaint Type"
                      values={{ type }}
                      rawId
                      filter={{ category }}
                      setFieldValue={(_: any, value: string) => {
                        // console.log(value);
                        setType(value ?? undefined);
                      }}
                      disabled={!category}
                    />
                  </div>
                </div>
              )}
              renderColumns={() => (
                <tr>
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-3 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Ticket # | Date
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Contact Person
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Category | Type
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Assignee
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Status
                  </th>
                </tr>
              )}
              renderLoader={() => (
                <tr>
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.DoubleShimmer />
                  </td>
                  <td className="px-6 py-4 w-4 border-b border-gray-200">
                    <Shimmers.AvatarShimmer />
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.DoubleShimmer />
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.DoubleShimmer />
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.ActionsShimmer actionsCount={2} />
                  </td>
                </tr>
              )}
              renderItem={(item) => (
                <tr
                  key={item._id}
                  className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
                  onClick={wrapClick(dispatchModal(item._id, "view"))}
                >
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                    <div className="font-medium text-gray-900 dark:text-gray-100">
                      {item?.code || "N/A"}
                    </div>
                    <div className="font-medium text-gray-500 dark:text-gray-400">
                      {moment(item?.createdAt).format(dateFormat)}
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                    <div className="text-gray-900 dark:text-gray-100">
                      {item?.contactPerson?.fullName || "N/A"}
                    </div>
                    <div className="text-gray-500 dark:text-gray-500">
                      {item?.contactPerson?.phoneNumber || "N/A"}
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                    <div className="text-gray-900 dark:text-gray-100">
                      {lodash.startCase(item?.category) || "N/A"}
                    </div>
                    <div className="text-gray-500 dark:text-gray-500">
                      {item?.type?.name || "N/A"}
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                    <div className="text-gray-900 dark:text-gray-100">
                      {displayUserName(item?.currentStage?.user) || "N/A"}
                    </div>
                    <div className="text-gray-500 dark:text-gray-500">
                      {item?.currentStage?.user?.code || "N/A"}
                    </div>
                  </td>
                  {!searchParams.status && (
                    <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                      <StatusBadge
                        status={item?.status}
                        options={{
                          Open: "bg-gray-100 text-gray-800",
                          InProgress: "bg-amber-100 text-amber-800",
                          Closed: "bg-green-100 text-green-800",
                        }}
                      />
                    </td>
                  )}
                </tr>
              )}
            />
          </div>
        </div>
      </div>

      <CreateTicketContainer
        open={createOpen}
        setOpen={setCreateOpen}
        refetch={refetch}
      />
      {!!searchParams.id?.length && (
        <>
          <ViewComplaintContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
        </>
      )}
    </main>
  );
};

export default ComplaintsPage;
