import { useQuery } from "@apollo/client";
import { Modal, Tabs } from "components";
import { useState } from "react";
import { useSearch } from "react-location";
import { GET_SERVICE_POINT } from "../customer/apollo";
import CustomerView from "../customer/cards/customer-view";
import {
  ServicePointComplaintHistoryContainer,
  ServicePointReadingHistoryContainer,
  ServicePointUpdateHistoryContainer,
} from "../customer/containers";
import { LocationGenerics } from "router/location";

const orderTabs = [
  {
    name: "Customer Details",
    href: "details",
  },
  {
    name: "Update History",
    href: "update-history",
  },
  {
    name: "Reading History",
    href: "reading-history",
  },
  {
    name: "Tickets",
    href: "complaint-history",
  },
];

export default function ViewCustomerContainer({
  open,
  setOpen,
  id,
}: {
  id?: string;
  open: boolean;
  setOpen: (val: boolean) => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const [orderTab, setOrderTab] = useState("details");

  const { data, loading } = useQuery(GET_SERVICE_POINT, {
    variables: {
      id: id || searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });

  return (
    <Modal
      open={open}
      loading={loading}
      setOpen={setOpen}
      hidePadding
      title="Service Point Information"
      size="5xl"
      description="Details of service point are shown below"
    >
      <>
        <Tabs tabs={orderTabs} onTabChanged={setOrderTab} />
        <div className="flex-1 w-full h-[75vh] overflow-y-auto sm:p-6">
          {orderTab === "details" && (
            <CustomerView servicePoint={data?.servicePoint} />
          )}
          {orderTab === "update-history" && (
            <ServicePointUpdateHistoryContainer
              servicePoint={data?.servicePoint?._id}
            />
          )}
          {orderTab === "reading-history" && (
            <ServicePointReadingHistoryContainer
              servicePoint={data?.servicePoint?._id}
            />
          )}
          {orderTab === "complaint-history" && (
            <ServicePointComplaintHistoryContainer
              servicePoint={data?.servicePoint?._id}
            />
          )}
        </div>
      </>
    </Modal>
  );
}
