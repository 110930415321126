import { PaperClipIcon } from "@heroicons/react/20/solid";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import { classNames, wrapClick } from "./../../utils";
// import { FilePreview } from "../../components/cards/file-preview";
import parse from "html-react-parser";
import lodash from "lodash";
import moment from "moment";
import { FC, useState } from "react";
import type { Complaint } from "./schema";
import ViewCustomerContainer from "./customer";

interface DetailsContainerProps {
  complaint?: Complaint;
}

const DetailsContainer: FC<DetailsContainerProps> = ({ complaint }) => {
  const [servicePointModalOpen, setServicePointModalOpen] = useState(false);
  return (
    <section aria-labelledby="applicant-information-title">
      <div
        className={classNames(
          complaint?.servicePoint ? "sm:rounded-t-lg" : "sm:rounded-lg",
          "bg-white shadow"
        )}
      >
        <div className="px-4 py-5 sm:px-6">
          <h2
            id="applicant-information-title"
            className="text-lg font-medium leading-6 text-gray-900"
          >
            Complaint Information
          </h2>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Personal details and complaint details.
          </p>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-3 gap-x-4 gap-y-8">
            <div className="">
              <dt className="text-sm font-medium text-gray-500">Ticket #</dt>
              <dd className="mt-1 text-sm text-gray-900">{complaint?.code}</dd>
            </div>
            <div className="">
              <dt className="text-sm font-medium text-gray-500">Category</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {lodash.startCase(complaint?.category)}
              </dd>
            </div>
            <div className="">
              <dt className="text-sm font-medium text-gray-500">Type</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {complaint?.type?.name}
              </dd>
            </div>
            <div className="">
              <dt className="text-sm font-medium text-gray-500">Status</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {lodash.startCase(complaint?.status)}
              </dd>
            </div>
            <div className="">
              <dt className="text-sm font-medium text-gray-500">Opened At</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {moment(complaint?.createdAt).format("DD/MM/YYYY hh:mm A")}
              </dd>
            </div>
            {complaint?.closedAt && (
              <div className="">
                <dt className="text-sm font-medium text-gray-500">Closed At</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {moment(complaint?.closedAt).format("DD/MM/YYYY hh:mm A")}
                </dd>
              </div>
            )}

            <div className="col-start-1">
              <dt className="text-sm font-medium text-gray-500">
                Contact Name
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {complaint?.contactPerson?.fullName || "N/A"}
              </dd>
            </div>
            <div className="">
              <dt className="text-sm font-medium text-gray-500">
                Contact Phone
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {complaint?.contactPerson?.phoneNumber || "N/A"}
              </dd>
            </div>
            <div className="">
              <dt className="text-sm font-medium text-gray-500">
                Contact Email
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {complaint?.contactPerson?.emailAddress || "N/A"}
              </dd>
            </div>
            <div className="sm:col-span-3">
              <dt className="text-sm font-medium text-gray-500">Description</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {complaint?.description ? parse(complaint?.description) : "N/A"}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Estimated Resolution Date</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {complaint?.estimatedResolutionDate ? moment(complaint?.estimatedResolutionDate).format("DD/MM/YYYY") : "N/A"}
              </dd>
            </div>
            <div className="sm:col-span-3">
              <dt className="text-sm font-medium text-gray-500">Attachments</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {complaint?.supportingDocumentUrls?.length ? (
                  <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
                    {complaint?.supportingDocumentUrls?.map(
                      (documentUrl, idx) => {
                        const documentName = lodash
                          .chain(documentUrl)
                          .split("/")
                          .last()
                          .value();
                        return (
                          <li
                            key={idx}
                            className="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
                          >
                            <div className="flex w-0 flex-1 items-center">
                              <PaperClipIcon
                                aria-hidden="true"
                                className="h-5 w-5 flex-shrink-0 text-gray-400"
                              />
                              <span className="ml-2 w-0 flex-1 truncate">
                                {documentName}
                              </span>
                            </div>
                            {/* <div className="ml-4 flex-shrink-0 space-x-3">
                              <FilePreview url={documentUrl} />
                              <a
                                href={documentUrl}
                                download={documentName}
                                className="font-medium text-primary-600 hover:text-primary-500"
                              >
                                Download
                              </a>
                            </div> */}
                          </li>
                        );
                      }
                    )}
                  </ul>
                ) : (
                  <div className="block w-full rounded-lg border-2 border-dashed border-gray-400 p-6 text-center">
                    <DocumentTextIcon
                      aria-hidden="true"
                      strokeWidth={1}
                      className="mx-auto text-sm h-8 w-8 text-gray-500"
                    />
                    <span className="mt-3 block text-sm text-gray-500">
                      No Files Were Attached
                    </span>
                  </div>
                )}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      {complaint?.servicePoint && (
        <div>
          <button
            onClick={wrapClick(() => {
              setServicePointModalOpen(true);
            })}
            className="block w-full bg-gray-50 px-4 py-4 text-center text-sm font-medium text-gray-500 hover:text-gray-700 sm:rounded-b-lg"
          >
            View Service Point Details
          </button>
          <ViewCustomerContainer
            open={servicePointModalOpen}
            setOpen={(val)=> val ? undefined :  setServicePointModalOpen(false) }
            id={complaint.servicePoint._id}
          />
        </div>
      )}
    </section>
  );
};

export default DetailsContainer;
