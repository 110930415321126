import { gql, useQuery } from "@apollo/client";
import { Modal } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import EnquiryView from "./cards/enquiry-view";

export const GET_ENQUIRY = gql`
  query GetEnquiry($id: ID!) {
    enquiry: getEnquiry(id: $id) {
      _id
      code
      type {
        _id
        code
        name
      }
      description
      supportingDocumentUrls
      createdAt
      updatedAt
      createdBy {
        ... on User {
          _id
          code
          firstName
          lastName
          profileImageUrl
          emailAddress
          phoneNumber
        }
        ... on PartnerUser {
          _id
          code
          firstName
          lastName
          profileImageUrl
          emailAddress
          phoneNumber
        }
      }
      contactPerson {
        emailAddress
        fullName
        phoneNumber
      }
    }
  }
`;

export default function ViewEnquiryContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_ENQUIRY, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });

  return (
    <Modal
      open={open}
      loading={loading}
      setOpen={setOpen}
      hidePadding
      size="5xl"
      title="Enquiry Information"
      description="Details of enquiry are shown below"
    >
      {data?.enquiry?._id && <EnquiryView enquiry={data?.enquiry} />}
    </Modal>
  );
}
