import { FC } from "react";
// import "mapbox-gl/dist/mapbox-gl.css";
// import Map, { MapProvider, Marker } from "react-map-gl";
// import config from "@repo/config";
// import mapboxgl from "mapbox-gl";
import {ImageView} from "../image-view";

// // @ts-ignore
// // eslint-disable-next-line import/no-webpack-loader-syntax
// mapboxgl.workerClass =
//   require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

interface MapViewProps {
  longitude: number;
  latitude: number;
}

export const MapView: FC<MapViewProps> = ({ longitude, latitude }) => {
  return (
    <ImageView url={`https://api.mapbox.com/styles/v1/mapbox/streets-v9/static/url-https%3A%2F%2Fdocs.mapbox.com%2Fapi%2Fimg%2Fcustom-marker.png(${longitude},${latitude})/${longitude},${latitude},15.5/1024x1024@2x?access_token=pk.eyJ1IjoicG9seW1vcnBobGFicyIsImEiOiJjbHU2czRmMDExdWF4MmlrMWEyd2F6eGh6In0.yxIBzfbNIDLTYMDGmAbPiA`} />
  );
  // return (
  //   <MapProvider>
  //     <Map
  //       id="service-location"
  //       longitude={longitude}
  //       latitude={latitude}
  //       zoom={15}
  //       mapStyle="mapbox://styles/mapbox/streets-v9"
  //       mapboxAccessToken={config.mapbox.token}
  //     >
  //       <Marker longitude={longitude} latitude={latitude}>
  //         <img
  //           className="w-6 h-6"
  //           src={"/logo.png"}
  //           alt={"pin"}
  //         />
  //       </Marker>
  //     </Map>
  //   </MapProvider>
  // );
};

export default MapView;
