import { gql, useQuery } from "@apollo/client";
import { useNavigate, useSearch } from "react-location";
import ComplaintView from "./cards/complaint-view";
import { Modal } from "components";
import { LocationGenerics } from "router/location";

export const GET_COMPLAINT = gql`
  query GetComplaint($getComplaintId: ID!) {
    complaint: getComplaint(id: $getComplaintId) {
      _id
      code
      category
      type {
        _id
        code
        name
      }
      description
      supportingDocumentUrls
      servicePointCode
      servicePoint {
        _id
        code
      }
      customerCode
      accountCode
      geoCode
      contractStatus
      meterCode
      status
      createdAt
      updatedAt
      createdBy {
        ... on User {
          _id
          code
          firstName
          lastName
          profileImageUrl
          emailAddress
          phoneNumber
        }
        ... on PartnerUser {
          _id
          code
          firstName
          lastName
          profileImageUrl
          emailAddress
          phoneNumber
        }
      }
      customer {
        code
        customerType

        organization {
          name
        }
        representative {
          phoneNumber
          phoneNumber
          emailAddress
          ghanaCardNumber
        }
      }
      contactPerson {
        emailAddress
        fullName
        phoneNumber
      }
      startedAt
      resolution {
        notes
        supportingDocumentUrls
      }
      closedAt
      userRole {
        _id
      }
      estimatedResolutionDate
    }
  }
`;

export default function ViewComplaintContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_COMPLAINT, {
    variables: {
      getComplaintId: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });

  return (
    <Modal
      open={open}
      loading={loading}
      setOpen={setOpen}
      hidePadding
      size="5xl"
      title="Ticket Information"
      description="Details of ticket are shown below"
      renderActions={() => (
        <>
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() =>
              navigate({
                to: `./${data?.complaint?._id}`,
              })
            }
          >
            View More
          </button>
        </>
      )}
    >
      {data?.complaint?._id && <ComplaintView complaint={data?.complaint} />}
    </Modal>
  );
}
