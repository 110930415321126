import { FC } from "react";
import Zoom from "react-medium-image-zoom";

interface ImageViewProps {
  url: string;
}

export const ImageView: FC<ImageViewProps> = ({ url }) => {
  if (!url) {
    return (
      <div className="border-2 border-dashed border-gray-300 rounded-md mt-2 h-64 items-center justify-center flex text-gray-600">
        Document/Image not provided
      </div>
    );
  }

  return (
    <Zoom classDialog="custom-zoom">
      <img
        src={url}
        alt="reading"
        className="h-64 w-full rounded-md object-cover object-center"
      />
    </Zoom>
  );
};

export default ImageView;
