import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { RichInput, SelectInput, TextInput } from "components";
import { DistrictPicker, EnquiryTypePicker, RegionPicker } from "containers";
import { FormikProps } from "formik";
import parse from "html-react-parser";
import { FC } from "react";
export { EnquiryFormSchema } from "./schema";

type Props = {
  form: FormikProps<any>;
};

export const EnquiryForm: FC<Props> = ({ form }) => {
  return (
    <div className="space-y-6 divide-y no-scrollbar divide-gray-200 flex-1 overflow-y-auto p-6">
      <div>
        <span className="text-xs font-light">Contact Person Information</span>
        <div className="grid grid-cols-3 gap-6 mt-2">
          <div className="col-span-3">
            <TextInput
              id="contactPersonInfo.fullName"
              label="Full Name"
              type="text"
              required
              placeholder="e.g. Emmanuel Baidoo"
              {...form}
            />
          </div>

          <div className="col-span-2">
            <TextInput
              id="contactPersonInfo.emailAddress"
              label="Email Address"
              type="text"
              placeholder="e.g. ebaidoo@office.com"
              {...form}
            />
          </div>
          <div className="">
            <TextInput
              id="contactPersonInfo.phoneNumber"
              label="Phone Number"
              type="text"
              placeholder="e.g. 0506339153"
              required
              {...form}
            />
          </div>
          <div className="space-y-1">
            <RegionPicker
              id="enquiryInfo.region"
              label="Region"
              required
              {...form}
              rawId
            />
          </div>
          <div>
            <DistrictPicker
              id="enquiryInfo.district"
              label="District"
              required
              disabled={!form.values?.enquiryInfo?.region}
              filter={{ region: form.values?.enquiryInfo?.region }}
              {...form}
              rawId
            />
          </div>
          <div className="">
            <TextInput
              id="enquiryInfo.ghanaPostAddress"
              label="Ghana Post Address"
              type="text"
              placeholder="e.g. GA-241-3431"
              {...form}
            />
          </div>
          <div className="col-span-3">
            <TextInput
              id="enquiryInfo.location"
              label="Location"
              type="text"
              placeholder="e.g. Tema Pig Farm"
              {...form}
            />
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Enquiry Type Information</span>
        <div className="grid grid-cols-3 gap-6 mt-2">
          <div className="space-y-1 col-span-2">
            <EnquiryTypePicker
              id="enquiryInfo.type"
              label="Enquiry Type"
              required
              {...form}
            />
          </div>
          {form.values.enquiryInfo?.type?.description && (
            <div className="rounded-md bg-yellow-50 p-4 col-span-3">
              <div className="flex">
                <div className="flex-shrink-0">
                  <InformationCircleIcon
                    className="h-5 w-5 text-yellow-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <div className="text-sm text-yellow-700">
                    <h2 className="font-medium">Info About Enquiry Type</h2>
                    <p className="mt-1">
                      {parse(form.values.enquiryInfo?.type?.description)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="col-span-3">
            <RichInput
              id="enquiryInfo.description"
              label="Description"
              placeholder="e.g. Connected the cables underground from next door"
              {...form}
            />
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Extra Information</span>
        <div className="grid grid-cols-3 gap-6 mt-2">
          <div className="space-y-1">
            <SelectInput
              id="enquiryInfo.source"
              label="Enquiry Source"
              required
              {...form}
              options={[
                { label: "--- Select One ---", value: "" },
                { label: "Email", value: "Email" },
                { label: "Instagram", value: "Instagram" },
                { label: "Messenger", value: "Messenger" },
                { label: "Phone Call", value: "PhoneCall" },
                { label: "SMS", value: "TextMessage" },
                { label: "Telegram", value: "Telegram" },
                { label: "Twitter (X)", value: "Twitter" },
                { label: "Web Portal", value: "WebPortal" },
                { label: "WhatsApp", value: "WhatsApp" },
              ]}
            />
          </div>
          {form.values?.enquiryInfo?.source === "PhoneCall" && (
            <div>
              <TextInput
                id="enquiryInfo.sourceId"
                label="Contact Id (Presence Id)"
                disabled={!form.values?.enquiryInfo?.source}
                {...form}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EnquiryForm;
