import * as Yup from "yup";

export const TicketFormSchema = Yup.object().shape({
  contactPersonInfo: Yup.object().shape({
    fullName: Yup.string()
      .min(10, "Name Should Be At Least 10 Characters")
      .required("Please Enter Name Of Contact Person"),
    phoneNumber: Yup.string()
      .matches(
        /^0(2|5)(0|2|3|4|5|6|7|9)\d{7}$/,
        "Kindly enter a valid phone number"
      )
      .required("Phone number is required"),
    emailAddress: Yup.string()
      .email("Please Enter A Valid Email Address")
      .notRequired(),
  }),
  complaintInfo: Yup.object().shape({
    type: Yup.object().nullable().required("Kindly Select Complaint Type"),
    category: Yup.object()
      .nullable()
      .required("Kindly Select Complaint Category"),
    description: Yup.string()
      .notOneOf(
        ["<p><br></p>"],
        "Kindly Provide A Description Of The Complaint"
      )
      .required("Kindly Provide A Description Of The Complaint"),
    supportingDocumentUrls: Yup.array(Yup.string()).when(
      ["type", "type.requiresDocument"],
      {
        is: (_: any, otherValue: boolean) => {
          return otherValue === true;
        },
        then: (schema) =>
          schema
            .min(1, "At Least A Image Or Document Is Required")
            .required("Kindly Upload Supporting Documents"),
        otherwise: (schema) => schema.notRequired(),
      }
    ),
  }),
});
