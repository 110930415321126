import { gql } from "@apollo/client";

export const GET_SERVICE_POINT = gql`
  query GetServicePoint($id: ID!) {
    servicePoint: getServicePoint(id: $id) {
      _id
      code
      qrCode
      propertyCode
      customerCode
      accountCode
      meterCode
      serviceType
      serviceClass
      geoCode
      meterLocation
      transformerNumber
      contractedDemand
      status
      subStatus
      customerRepresentative {
        title
        fullName
        phoneNumber
        emailAddress
        profileImageUrl
        ghanaCardNumber
        identityCardNumber
        identityCardType
        nationality
        hasGhanaCard
        identityCardBackImageUrl
        identityCardExpiryDate
        identityCardFrontImageUrl
        identityCardIssueDate
        ghanaCardBackImageUrl
        ghanaCardExpiryDate
        ghanaCardFrontImageUrl
        ghanaCardIssueDate
        gender
        dateOfBirth
        extraEmailAddresses
        extraPhoneNumbers
      }
      customerOrganization {
        name
        organizationRegistrationNumber
        organizationRegistrationDate
        organizationRegistrationDocumentUrl
        taxIdentificationNumber
      }
      customerType
      customerCode
      meta {
        lastReadingDate
        lastBillDate
        lastReadingValue
      }
      tariffClass {
        name
        _id
        serviceClass
      }
      property {
        regionName
        districtName
        blockName
        roundName
        plotCode
        code
        _id
        ghanaPostAddress
        streetName
        structureNumber
        closestLandmark
        address
        owner {
          fullName
          phoneNumber
        }
        premiseType {
          code
          name
        }
        premiseCategory {
          code
          name
        }
        sitePlanDocumentUrl
        geoLocation {
          coordinates
          type
        }
      }
      customerType
      meter {
        code
        model {
          name
          code
        }
        brand {
          code
          name
        }
        modelType
        modelMeta {
          phase
        }
      }
      structureNumber
      activity {
        name
      }
      subActivity {
        name
      }
      cartesianLocation {
        easting
        northing
      }
      energyCertificateDocumentUrl
      energyCertificateNumber
      district {
        _id
      }
      address
      payer {
        fullName
        primaryPhoneNumber
        primaryEmailAddress
        phoneNumbers
        emailAddresses
      }
      block {
        _id
      }
      blockCode
      blockName
      district {
        _id
      }
      districtCode
      districtName
      plot {
        _id
        code
        name
      }
      plotName
      plotCode
      region {
        _id
      }
      regionName
      regionCode
      poleNumber
      round {
        _id
      }
      roundCode
      roundName
      extraAttributes {
        _id
        name
        value
      }
    }
  }
`;
