import { gql, useQuery } from "@apollo/client";
import { Disclosure } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import {
  ArrowsUpDownIcon,
  FlagIcon,
  LightBulbIcon,
  PencilSquareIcon,
  PowerIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";
import { Loader } from "components";
import lodash from "lodash";
import moment from "moment";
import { FC } from "react";
import { classNames, displayUserName } from "utils";

function removeEmptyValues(obj: Record<string, any>) {
  return lodash.pickBy(obj, (value, key) => {
    if (key === "__typename") {
      return false; // Exclude keys with "__typename"
    }
    if (lodash.isArray(value)) {
      return !lodash.isEmpty(value); // Check if the array is not empty
    }
    return !lodash.isNull(value) && !lodash.isEqual(value, ""); // Check for null and empty string
  });
}

function renderChanges(obj: Record<string, any>) {
  const newObj = removeEmptyValues(obj);
  if (!newObj.length) {
    return (<p className="text-sm text-gray-600 font-light">No Updates</p>);
  }
  return lodash.map(newObj, (value, key) => {
    return (
      <div key={key}>
        <span className="font-thin text-xs text-gray-900">
          {lodash.startCase(key)}
        </span>
        <p className="text-sm text-gray-600 font-light">
          {lodash.isArray(value) ? value.join(", ") || "N/A" : value || "N/A"}
        </p>
      </div>
    );
  });
}

const GET_UPDATE_HISTORY = gql`
  query GetServicePointUpdates(
    $servicePoint: ID
    $page: Int
    $pageSize: Int
    $sort: String
  ) {
    updates: getServicePointHistories(
      servicePoint: $servicePoint
      page: $page
      pageSize: $pageSize
      sort: $sort
    ) {
      action
      description
      updateReason
      servicePointCode
      createdAt
      actor {
        code
        lastName
        firstName
        profileImageUrl
        roleName
        roleCode
      }
      data {
        oldMeterCode
        oldMeterBrandCode
        oldMeterBrandName
        oldMeterModelCode
        oldMeterModelName
        oldMeterModelType
        newMeterCode
        newMeterBrandCode
        newMeterBrandName
        newMeterModelCode
        newMeterModelName
        newMeterModelType
        oldTariffCode
        oldTariffName
        newTariffCode
        newTariffName
        newTariffEffectiveDate
        oldRegionName
        oldDistrictName
        oldBlockName
        oldRoundName
        oldPlotCode
        oldPlotName
        oldEasting
        oldNorthing
        oldZone
        oldStructureNumber
        oldGeoCode
        oldAddress
        oldGhanaPostAddress
        newRegionName
        newDistrictName
        newBlockName
        newRoundName
        newPlotCode
        newPlotName
        newEasting
        newNorthing
        newZone
        newStructureNumber
        newGeoCode
        newAddress
        newGhanaPostAddress
        oldCustomerType
        newCustomerType
        oldFullName
        oldPrimaryPhoneNumber
        oldPrimaryEmailAddress
        oldPhoneNumbers
        oldEmailAddresses
        newFullName
        newPrimaryPhoneNumber
        newPrimaryEmailAddress
        newPhoneNumbers
        newEmailAddresses
        oldReadingValue
        oldReadingDate
        newReadingValue
        newReadingDate
        suspendType
      }
    }
  }
`;

interface ServicePointUpdateHistoryContainerProps {
  servicePoint: string;
}

export const ServicePointUpdateHistoryContainer: FC<
  ServicePointUpdateHistoryContainerProps
> = ({ servicePoint }) => {
  const { loading, data } = useQuery(GET_UPDATE_HISTORY, {
    notifyOnNetworkStatusChange: false,
    variables: {
      servicePoint,
      sort: "-createdAt",
      page: 1,
      pageSize: 10,
    },
  });

  const actionIconMap = {
    MeterInstalled: (
      <span
        className={classNames(
          "bg-green-500",
          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
        )}
      >
        <LightBulbIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    MeterReplaced: (
      <span
        className={classNames(
          "bg-green-500",
          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
        )}
      >
        <ArrowsUpDownIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    TariffUpdated: (
      <span
        className={classNames(
          "bg-green-500",
          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
        )}
      >
        <PencilSquareIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    ContractSuspended: (
      <span
        className={classNames(
          "bg-red-500",
          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
        )}
      >
        <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    ContractTerminated: (
      <span
        className={classNames(
          "bg-red-500",
          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
        )}
      >
        <PowerIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    IdentityUpdated: (
      <span
        className={classNames(
          "bg-green-500",
          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
        )}
      >
        <PencilSquareIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    LocationUpdated: (
      <span
        className={classNames(
          "bg-green-500",
          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
        )}
      >
        <PencilSquareIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    ContractRestored: (
      <span
        className={classNames(
          "bg-green-500",
          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
        )}
      >
        <PowerIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    ContactUpdated: (
      <span
        className={classNames(
          "bg-green-500",
          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
        )}
      >
        <PencilSquareIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    MeterRead: (
      <span
        className={classNames(
          "bg-green-500",
          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
        )}
      >
        <PrinterIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    FlaggedForDisconnection: (
      <span
        className={classNames(
          "bg-amber-500",
          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
        )}
      >
        <FlagIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
  };

  if (loading) {
    <div className="flex min-h-[65vh] items-center justify-center">
      <Loader />
    </div>;
  }

  return data?.updates?.length > 0 ? (
    <div className="flow-root  min-h-[65vh]">
      <ul className="-mb-8">
        {data?.updates?.map((historyItem: any, historyItemIdx: number) => (
          <li key={historyItemIdx}>
            <div className="relative pb-8">
              {historyItemIdx !== data?.updates.length - 1 ? (
                <span
                  className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                  aria-hidden="true"
                />
              ) : null}
              <div className="relative flex space-x-3">
                <div>{lodash.get(actionIconMap, historyItem?.action, "")}</div>
                <div className="flex min-w-0 flex-1 justify-between space-x-12 pt-1.5">
                  <div className="flex-1">
                    <HistoryDetails history={historyItem} />
                  </div>
                  <div className="whitespace-nowrap text-right text-sm flex flex-col text-gray-500">
                    <time dateTime={historyItem?.createdAt}>
                      {moment(historyItem?.createdAt).format("DD/MM/YYYY")}
                    </time>
                    <time dateTime={historyItem?.createdAt}>
                      {moment(historyItem?.createdAt).format("hh:mm A")}
                    </time>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  ) : (
    <div className="flex min-h-[65vh] items-center justify-center">
      <div className="text-center">
        <svg
          className="mx-auto h-12 w-12 text-gray-400 dark:text-gray-300"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
          />
        </svg>
        <h3 className="mt-2 text-sm font-medium text-gray-900 dark:text-gray-100">
          No History Data yet
        </h3>
        <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
          This customer has no history data yet
        </p>
      </div>
    </div>
  );
};

export default ServicePointUpdateHistoryContainer;

const HistoryDetails = ({ history }: { history: any }) => {
  return (
    <>
      <div className="text-sm text-gray-500">
        <span className="font-medium text-gray-900">
          {displayUserName(history?.actor)}
        </span>{" "}
        {lodash.split(history?.action, /(?=[A-Z])/).join(" ")}
        <Disclosure
          defaultOpen
          as="div"
          className="relative p-4 border border-dashed grid-cols-4 border-gray-300 rounded mt-6"
        >
          <Disclosure.Button className="absolute bg-white px-2 -top-2 text-xs">
            Changes
          </Disclosure.Button>
          <Disclosure.Panel className="grid mt-1 grid-cols-4 gap-4">
            {renderChanges(history?.data)}
          </Disclosure.Panel>
        </Disclosure>
      </div>
      {history?.description && (
        <div className="relative p-4 border border-gray-300 rounded mt-6">
          <span className="absolute bg-white px-2 -top-2 text-xs">Notes</span>
          <p className="text-sm text-gray-600 font-light">
            {history.description}
          </p>
        </div>
      )}
    </>
  );
};
