/* eslint-disable no-template-curly-in-string */
import { useMutation, useReactiveVar } from "@apollo/client";
import { QuestionMarkCircleIcon, UserIcon } from "@heroicons/react/20/solid";
import {
  PencilSquareIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/24/outline";
import { currentUserVar } from "apollo/cache/auth";
import { Avatar, RichInput } from "components";
import { useFormik } from "formik";
import parse from "html-react-parser";
import lodash from "lodash";
import moment from "moment";
import { FC, useMemo, useState } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import { classNames, displayUserName, wrapClick } from "utils";
import { UPDATE_COMPLAINT_STAGE } from "./queries";
import { Complaint } from "./schema";
import ServiceOrderContainer from "./service-order";

const tabs = [
  { name: "Notes", href: "Notes" },
  { name: "Service Orders", href: "Orders" },
];

const stagesMap = {
  Start: "Work Started",
  Escalate: "Escalating Ticket",
  Escalated: "Escalating Ticket",
  ExtraWork: "Completed Resolution On Extra",
  ExtraWorkNo: "Completed Resolution On Extra",
  HubtelWork: "Work On Hubtel",
  HubtelWorkDone: "Work On Hubtel",
  AdoraWork: "Work On Adora",
  AdoraWorkDone: "Work On Adora",
  ZeusWork: "${_.startCase(serviceOrderType)} On ZEUS",
  ServiceOrder: "${_.startCase(serviceOrderType)} On ZEUS",
  Completed: "Closing Ticket",
  Notes: "Additional Note",
};

interface ResolutionContainerProps {
  complaint?: Complaint;
  refetch: () => void;
}

const ResolutionContainer: FC<ResolutionContainerProps> = ({
  complaint,
  refetch,
}) => {
  const [viewOrder, setViewOrder] = useState<Complaint["currentStage"] | undefined>(
    undefined,
  );
  const currentUser = useReactiveVar(currentUserVar);
  const isAssignedToMe = complaint?.currentStage?.user ? currentUser?._id === complaint?.currentStage?.user?._id : false;
  const [currentTab, setCurrentTab] = useState("Notes");

  const [notes, orders] = useMemo(
    () => [
      lodash.filter(complaint?.stages, "notes"),
      lodash.filter(complaint?.stages, "serviceOrder"),
    ],
    [complaint?.stages],
  );
  const [updateComplaintStage] = useMutation(UPDATE_COMPLAINT_STAGE, {
    onCompleted: () => {
      refetch();
    },
  });
  const form = useFormik({
    initialValues: {
      notes: "",
      supportingDocumentUrls: [],
    },
    onSubmit: async (values) => {
      await updateComplaintStage({
        variables: {
          id: complaint?._id,
          notes: values.notes,
          supportingDocumentUrls: values.supportingDocumentUrls,
          stage: "Notes",
        },
      });
      form.resetForm();
    },
  });

  return (
    <section aria-labelledby="notes-title">
      <div className="bg-white shadow sm:overflow-hidden sm:rounded-lg">
        <div className="divide-y divide-gray-200">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="notes-title" className="text-lg font-medium text-gray-900">
              Resolution Information
            </h2>
          </div>
          <div className="hidden sm:block">
            <div className=" px-6">
              <nav aria-label="Tabs" className="-mb-px flex space-x-8">
                {tabs.map((tab) => (
                  <button
                    key={tab.name}
                    onClick={wrapClick(() => {setCurrentTab(tab.href)})}
                    aria-current={tab.href === currentTab ? "page" : undefined}
                    className={classNames(
                      tab.href === currentTab
                        ? "border-primary-500 text-primary-600"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                      "whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium",
                    )}
                  >
                    {tab.name}
                  </button>
                ))}
              </nav>
            </div>
          </div>
          {currentTab === "Notes" && (
            <div className="px-4 py-6 sm:px-6 max-h-96 overflow-y-auto">
              {notes.length ? (
                <ul className="space-y-8">
                  {notes.map((item) => (
                    <li key={item._id}>
                      <div className="flex space-x-3">
                        <div className="flex-shrink-0">
                          <Avatar
                            alt={displayUserName(item?.user).trim() || "N A"}
                            src={item?.user?.profileImageUrl || ""}
                          />
                        </div>
                        <div>
                          <div className="text-sm">
                            <span className="font-medium text-gray-900">
                              {displayUserName(item.user)}
                            </span>
                          </div>
                          <div className="mt-1 text-sm text-gray-700">
                            <p>{parse(item.notes)}</p>
                          </div>
                          <div className="mt-2 space-x-2 text-sm">
                            <span className="font-medium text-gray-500">
                              {moment(item.timestamp).calendar({
                                lastDay: "[Yesterday at] hh:mm A",
                                sameDay: "[Today at] hh:mm A",
                                nextDay: "[Tomorrow at] hh:mm A",
                                lastWeek: "DD/MM/YYYY hh:mm A",
                                nextWeek: "dddd [at] hh:mm A",
                                sameElse: "DD/MM/YYYY hh:mm A",
                              })}
                            </span>{" "}
                            <span className="font-medium text-gray-500">
                              &middot;
                            </span>{" "}
                            <button
                              type="button"
                              className="font-medium text-gray-900"
                            >
                              {lodash.template(stagesMap[item.stage])(item)}
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="block w-full rounded-lg border-2 border-dashed border-gray-400 p-12 text-center">
                  <PencilSquareIcon
                    aria-hidden="true"
                    strokeWidth={1}
                    className="mx-auto text-sm h-8 w-8 text-gray-500"
                  />
                  <span className="mt-5 block text-sm text-gray-500">
                    No Notes{" "}
                    {complaint?.status === "Closed" ? "Were" : "Have Been"} Made
                  </span>
                </div>
              )}
            </div>
          )}
          {currentTab === "Orders" && (
            <div className="px-4 py-6 sm:px-6 max-h-96 overflow-y-auto">
              {orders?.length ? (
                <ul className="space-y-8">
                  {orders.map((item) => {
                    const status =
                      item?.serviceOrder?.disconnectionStatus ??
                      item?.serviceOrder?.faultStatus ??
                      item?.serviceOrder?.investigationStatus ??
                      item?.serviceOrder?.terminationStatus ??
                      item?.serviceOrder?.replacementStatus ??
                      item?.serviceOrder?.reconnectionStatus;
                    return (
                      <li key={item._id}>
                        <div className="flex space-x-3">
                          <div className="flex-shrink-0">
                            <Avatar
                              alt={displayUserName(item?.user).trim() || "N A"}
                              src={item?.user?.profileImageUrl || ""}
                            />
                          </div>
                          <div className="w-full">
                            <div className="text-sm">
                              <span className="font-medium text-gray-900">
                                {displayUserName(item.user)}
                              </span>
                            </div>
                            <div className="mt-5 rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-center space-x-6 flex-1 w-full">
                              <div className="flex-shrink-0">
                                <span
                                  className={classNames(
                                    "bg-gray-400",
                                    "flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white",
                                  )}
                                >
                                  <UserIcon
                                    aria-hidden="true"
                                    className="h-5 w-5 text-white"
                                  />
                                </span>
                              </div>
                              <div className="flex-1">
                                <div className="text-sm font-medium text-gray-900 flex">
                                  #{item?.serviceOrder?.code}
                                  <span
                                    aria-hidden="true"
                                    className="hidden sm:mx-2 sm:inline"
                                  >
                                    &middot;
                                  </span>
                                  <div className="text-gray-600">{status}</div>
                                </div>
                                <div className="mt-1 text-sm text-gray-600 sm:flex sm:items-center">
                                  <div className="mt-1 sm:mt-0">
                                    Last updated on{" "}
                                    {moment(
                                      item?.serviceOrder?.updatedAt,
                                    ).format("DD/MM/YYYY [at] hh:mm a")}
                                  </div>
                                </div>
                              </div>
                              <div className="flex-shrink-0">
                                <button
                                  type="button"
                                  onClick={wrapClick(() => setViewOrder(item))}
                                  className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                >
                                  View Details
                                </button>
                              </div>
                            </div>
                            <div className="mt-2 space-x-2 text-sm">
                              <span className="font-medium text-gray-500">
                                {moment(item.timestamp).calendar({
                                  lastDay: "[Yesterday at] hh:mm A",
                                  sameDay: "[Today at] hh:mm A",
                                  nextDay: "[Tomorrow at] hh:mm A",
                                  lastWeek: "DD/MM/YYYY hh:mm A",
                                  nextWeek: "dddd [at] hh:mm A",
                                  sameElse: "DD/MM/YYYY hh:mm A",
                                })}
                              </span>{" "}
                              <span className="font-medium text-gray-500">
                                &middot;
                              </span>{" "}
                              <button
                                type="button"
                                className="font-medium text-gray-900"
                              >
                                {lodash.startCase(item.serviceOrderType)}
                              </button>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <div className="block w-full rounded-lg border-2 border-dashed border-gray-400 p-12 text-center">
                  <WrenchScrewdriverIcon
                    aria-hidden="true"
                    strokeWidth={1}
                    className="mx-auto text-sm h-8 w-8 text-gray-500"
                  />
                  <span className="mt-5 block text-sm text-gray-500">
                    No Service Orders{" "}
                    {complaint?.status === "Closed" ? "Were" : "Have Been"}{" "}
                    Generated
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
        {complaint?.status !== "Closed" && isAssignedToMe && (
          <div className="bg-gray-50 px-4 py-6 sm:px-6">
            <div className="flex space-x-3">
              <div className="flex-shrink-0">
                <Avatar
                  alt={displayUserName(currentUser) || "N A"}
                  src={currentUser?.profileImageUrl || ""}
                />
              </div>
              <div className="min-w-0 flex-1">
                <form onSubmit={form.handleSubmit}>
                  <div>
                    <label htmlFor="comment" className="sr-only">
                      Notes
                    </label>
                    <RichInput
                      id="notes"
                      label=""
                      placeholder="e.g. Add a note"
                      {...form}
                    />
                  </div>
                  <div className="mt-3 flex items-center justify-between">
                    <span className="group inline-flex items-start space-x-2 text-sm text-gray-500 hover:text-gray-900">
                      <QuestionMarkCircleIcon
                        aria-hidden="true"
                        className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                      />
                      <span>Any extra notes on ticket is okay.</span>
                    </span>
                    <button
                      type="submit"
                      disabled={form.isSubmitting}
                      className={classNames(
                        form.isSubmitting
                          ? "bg-primary-500 cursor-not-allowed"
                          : "bg-primary-600",
                        "inline-flex w-full items-center justify-center rounded-md px-6 py-3 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 sm:w-auto",
                      )}
                    >
                      Add Note
                      {form.isSubmitting && (
                        <PulseLoader
                          color={"#FFFFFF"}
                          loading={true}
                          cssOverride={{
                            marginLeft: "8px",
                          }}
                          size={5}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
      {viewOrder?.serviceOrder && (
        <ServiceOrderContainer
          open={Boolean(viewOrder)}
          close={() => {setViewOrder(undefined)}}
          serviceOrderType={viewOrder.serviceOrderType}
          serviceOrder={viewOrder.serviceOrder}
        />
      )}
    </section>
  );
};

export default ResolutionContainer;
