import { gql, useQuery } from "@apollo/client";
import { FC } from "react";
import { SearchSelectInput } from "components/core";

interface EnquiryTypePickerContainerProps {
  filter?: {};
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any;
  setFieldValue: any;
  setFieldTouched?: any;
  setFieldError?: any;
  rawId?: boolean;
}

const GET_ENQUIRY_TYPES = gql`
  query GetEnquiryTypes(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
  ) {
    enquiryTypes: getEnquiryTypes(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
    ) {
      _id
      code
      name
      description
      createdAt
      updatedAt
    }
  }
`;

const EnquiryTypePickerContainer: FC<EnquiryTypePickerContainerProps> = ({
  filter,
  id,
  label,
  rawId,
  ...form
}) => {
  const { loading, data } = useQuery(GET_ENQUIRY_TYPES, {
    variables: {
      ...(filter ? filter : {}),
    },
    notifyOnNetworkStatusChange: false,
  });

  return (
    <>
      <SearchSelectInput
        id={id ?? "type"}
        label={label ?? ""}
        placeholder="Select Enquiry Type"
        optionsLoading={loading}
        options={(data?.enquiryTypes ?? [])?.map((enquiryType: any) => ({
          label: {
            title: `${enquiryType.name}`,
          },
          value: rawId ? enquiryType._id : enquiryType,
        }))}
        {...form}
      />
    </>
  );
};

export default EnquiryTypePickerContainer;
