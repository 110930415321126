import { gql, useQuery } from '@apollo/client';
import { SearchSelectInput } from 'components/core';
import { FC } from 'react';

interface RegionPickerContainerProps {
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any
  setFieldValue: any;
  setFieldTouched?: any;
  setFieldError?: any;
  rawId?: boolean;
}

const GET_REGIONS = gql`
  query GetRegionsSelect {
    regions: getRegions(sort: "name") {
      _id
      code
      name
    }
  }
`;

const RegionPickerContainer: FC<RegionPickerContainerProps> = ({ id, label, rawId, ...form }) => {
  const { loading, data } = useQuery(GET_REGIONS, {
    notifyOnNetworkStatusChange: false
  })

  return (
    <SearchSelectInput
      id={id ?? "region"}
      label={label ?? "Region"}
      placeholder="Select Region"
      optionsLoading={loading}
      options={(data?.regions ?? [])?.map((region: any) => ({
        label: {
          title: region.name as string,
        },
        value: rawId ? region?._id : region
      }))}
      {...form}
      disabled={form.disabled}
    />
  )
}

export default RegionPickerContainer