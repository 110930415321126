import { displayUserName } from "../../utils";
import { Avatar } from "components"
import { FC } from "react";
import { Complaint } from "./schema";

interface MembersContainerProps {
  complaint: Complaint;
}

const MembersContainer: FC<MembersContainerProps> = ({ complaint }) => {
  return (
    <section aria-labelledby="timeline-title">
      <div className="bg-white px-4 py-6 shadow sm:rounded-lg sm:px-6">
        <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
          Ticket Members
        </h2>
        <div className="mt-6 grid grid-cols-1 gap-4 sm:grid-cols-2">
          {complaint?.members?.map((member) => (
            <div
              key={member._id}
              className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white p-4 shadow-sm focus-within:ring-2 focus-within:ring-primary-500 focus-within:ring-offset-2 hover:border-gray-400"
            >
              <div className="flex-shrink-0">
                <Avatar
                  alt={displayUserName(member).trim() || "N A"}
                  src={member?.profileImageUrl || ""}
                />
              </div>
              <div className="min-w-0 flex-1">
                <div className="focus:outline-none">
                  <span aria-hidden="true" className="absolute inset-0" />
                  <p className="text-sm font-medium text-gray-900 truncate line-clamp-1">
                    {displayUserName(member)}
                  </p>
                  <p className="truncate text-sm text-gray-500">
                    {member.roleName || "N/A"}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default MembersContainer;
