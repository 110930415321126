import { useReactiveVar } from "@apollo/client";
import { FC } from "react";
import { useSearch, MakeGenerics, Navigate, Outlet } from "react-location";
import { currentTokenVar } from "apollo/cache/auth";
// import { ReactComponent as BoobiesSvg } from 'assets/boobies.svg';

export type AuthLocationGenerics = MakeGenerics<{
  Search: {
    redirect?: string;
  };
}>;

const AuthLayout: FC = () => {
  const search = useSearch<AuthLocationGenerics>();
  const currentToken = useReactiveVar(currentTokenVar);

  if (currentToken) {
    // check if theres a token
    // if yes hit server to reauthenticate and redirect to app
    return <Navigate to={search?.redirect ?? "/"} replace />;
  }
  return (
    <div className="h-screen flex relative">
      <div className="absolute inset-0 text-primary-300 bg-gray-600 bg-opacity-5 blur-sm">
      {/* <BoobiesSvg className="object-cover w-full h-full" stroke="currentColor" fill="currentColor" /> */}
      </div>
      <div className="flex-1 flex flex-col justify-center items-center py-12 px-4 sm:px-6 lg:px-20 xl:px-24 relative">
        <div className="bg-white/95 p-6 rounded-lg shadow-sm">
        <Outlet />
        </div>
        <span className="absolute text-sm bottom-8 text-center inset-x-0">
          &copy; {new Date().getFullYear()} - Powered by{" "}
          <a
            href="https://www.polymorphlabs.io"
            target={"_blank"}
            className="text-primary-700 hover:text-primary-900"
            rel="noreferrer"
          >
            Polymorph Labs Ghana Limited
          </a>
        </span>
      </div>
    </div>
  );
};

export default AuthLayout;
