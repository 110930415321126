import { gql } from "@apollo/client";

export const GET_SERVICE_POINTS = gql`
  query GetServicePoints(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
    $region: ID
    $district: ID
    $block: ID
    $round: ID
    $plot: ID
    $property: ID
    $tariffClass: ID
    $customerType: CustomerType
    $serviceType: ServiceType
    $status: ServicePointStatus
  ) {
    rows: getServicePoints(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      block: $block
      round: $round
      plot: $plot
      property: $property
      tariffClass: $tariffClass
      customerType: $customerType
      serviceType: $serviceType
      status: $status
    ) {
      _id
      code
      createdAt
      updatedAt
      accountCode
      customerCode
      meterCode
      geoCode
      propertyCode
      customerType

      customerOrganization {
        name
      }
      customerRepresentative {
        fullName
        emailAddress
        phoneNumber
        profileImageUrl
      }
      status
      subStatus
      ghanaPostAddress
      address
      tariffClassCode
      tariffClassName
    }
    count: getServicePointsCount(
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      block: $block
      round: $round
      plot: $plot
      property: $property
      tariffClass: $tariffClass
      customerType: $customerType
      serviceType: $serviceType
      status: $status
    )
  }
`;

