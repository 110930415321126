import React, { useCallback, useEffect, useState } from "react";
import { useDropzone, Accept, FileRejection } from "react-dropzone";
import axios from "axios";
import config from "config";
import lodash from "lodash";
import { classNames, wrapClick, wrapImage } from "utils";
import { XMarkIcon } from "@heroicons/react/20/solid";
import numeral from "numeral";
import toast from "react-hot-toast";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";

import { ReactComponent as PdfSvg } from "assets/pdf-icon.svg";
import { ReactComponent as WordSvg } from "assets/word-icon.svg";

axios.defaults.baseURL = config.asset.uri;

interface UploadAreaProps {
  id: string;
  multiple?: boolean;
  maxFiles?: number;
  maxSize?: number;
  minSize?: number;
  accept?: Accept;
  disabled?: boolean;
  label?: string;
  values: any;
  setFieldValue: any;
  setFieldError: any;
  setFieldTouched: any;
  handleBlur: any;
  errors?: any;
  touched?: any;
  className?: string;
  required?: boolean;
}

interface Upload extends File {
  path: any;
  preview: string;
  fileUrl: string;
  error: string;
  progress: number;
  loading: boolean;
}

const FileIconMappings = {
  "application/pdf": <PdfSvg className='w-full h-full' />,
  "application/msword": <WordSvg className='w-full h-full' />,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": (
    <WordSvg className='w-full h-full' />
  ),
};

const UploadArea: React.FC<UploadAreaProps> = ({
  multiple = false,
  maxFiles = 5,
  maxSize = 1024 * 1024 * 5,
  minSize = 1,
  accept = {
    "image/*": [".png", ".jpeg", ".jpg"],
    "application/pdf": [".pdf"],
    "application/msword": [".doc", ".docx"],
  },
  errors,
  values,
  setFieldValue,
  setFieldError,
  setFieldTouched,
  touched,
  id,
  label,
  className = "",
  required,
}) => {
  const [files, setFiles] = useState<Upload[]>([]);
  const [, setLoading] = useState<boolean>(false);
  const [currentFilePointer, setCurrentFilePointer] = useState<
    number | undefined
  >(undefined);

  const removeFile = (_id: number) => () => {
    setFiles(lodash.filter(files, (file, index) => index !== _id));
    setFieldValue?.(
      id,
      lodash.filter(
        lodash.get(values, id, []) as string[],
        (file, index) => index !== _id
      )
    );
  };

  const updateFileProgress = (_id: number, progress: number) => {
    setFiles((files) =>
      lodash.map(files, (file, index) => {
        if (index === _id) {
          Object.assign(file, { progress, loading: true });
        }
        return file;
      })
    );
  };

  const completeFile = (_id: number, fileUrl: string) => {
    setFiles((files) =>
      lodash.map(files, (file, index) => {
        if (index === _id) {
          Object.assign(file, { fileUrl, loading: false });
        }
        return file;
      })
    );
  };

  const errorFile = (id: number, error: string) => {
    setFiles((files) =>
      lodash.map(files, (file, index) => {
        if (index === id) {
          Object.assign(file, { error, loading: false });
        }
        return file;
      })
    );
  };

  const onDrop = useCallback(
    async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      setFieldTouched(false);
      if (!acceptedFiles.length) {
        setFieldError(
          id,
          lodash
            .chain(rejectedFiles)
            .map("errors")
            .flatten()
            .map("code")
            .map(lodash.startCase)
            .join(", ")
            .value()
        );
        //Show a toast message for each rejected file
        lodash.forEach(rejectedFiles, (file) => {
          toast(
            JSON.stringify({
              type: "error",
              title: `Cannot upload file:${file.file.name} - ${lodash.startCase(
                file.errors[0].code
              )}`,
            })
          );
        });
        return;
      }
      setFiles((prev) =>
        multiple
          ? [
              ...prev,
              ...acceptedFiles.map((file: any) =>
                Object.assign(file, {
                  preview: URL.createObjectURL(file),
                  loading: true,
                  // preview: new Blob([file], {type: file?.type})
                })
              ),
            ]
          : acceptedFiles.map((file: any) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
                loading: true,
                // preview: new Blob([file], {type: file?.type})
              })
            )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // files,
      multiple,
      id,
    ]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles,
    multiple,
    accept,
    maxSize,
    minSize,
    noClick: false,
    disabled: false,
  });

  useEffect(() => {
    const nextPointer = files.findIndex((file) => file?.loading);
    if (nextPointer !== -1) {
      setCurrentFilePointer(nextPointer);
    } else {
      setCurrentFilePointer(undefined);
    }
  }, [files]);

  useEffect(
    () => () => {
      // Make sure to revoke the Object URL to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file?.preview));
    },
    [files]
  );

  useEffect(() => {
    //Clear files if form is reset
    if (!lodash.get(values, id)) {
      setFiles([]);
    }
  }, [values, id]);

  useEffect(() => {
    async function uploadFiles() {
      if (currentFilePointer === undefined) {
        return;
      }
      setLoading(true);
      // upload image to server and return url
      const data = new FormData();
      const file = files[currentFilePointer];
      data.append("file", file);
      await axios
        .post("/upload", data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / (progressEvent?.total ?? 0)
            );
            updateFileProgress(currentFilePointer, percentCompleted);
          },
        })
        .then(({ data }) => {
          completeFile(currentFilePointer, data);
          multiple
            ? setFieldValue?.(id, [...lodash.get(values, id), data])
            : setFieldValue?.(id, data);
        })
        .catch((err) => {
          console.log(err, "--ERROR__");
          errorFile(currentFilePointer, err?.message);
        });
      setLoading(false);
    }
    if (files.length && currentFilePointer !== undefined) {
      uploadFiles();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilePointer]);

  return (
    <>

      <label htmlFor={id} className='block text-sm font-medium text-gray-700'>
        {label} {required ? <span className='text-red-500'>*</span> : ""}
      </label>
      <div
        className={classNames(
          className,
          multiple ? "aspect-w-12 aspect-h-4 mt-1" : ""
        )}
      >
        <div className='flex space-x-2'>
          <div
            {...getRootProps()}
            id={`${id}-dropzone`}
            className={classNames(
              multiple
                ? "group w-4/12 flex-shrink-0 border-2 border-gray-300 border-dashed rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 flex flex-col items-center justify-center space-y-1 bg-gray-50 group-hover:opacity-75"
                : "relative group flex-1 flex border-2 mt-1 border-gray-300 border-dashed rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 aspect-w-3 aspect-h-2 w-full"
            )}
          >
            <input
              {...getInputProps()}
              id={id}
              name={id}
              type='file'
              className='sr-only'
            />

            {!files.length || multiple ? (
              <div
                className={classNames(
                  !multiple
                    ? "space-y-1 flex flex-1 flex-col items-center justify-center text-center px-6 pt-5 pb-6"
                    : ""
                )}
              >
                <svg
                  className='mx-auto h-12 w-12 text-gray-400'
                  stroke='currentColor'
                  fill='none'
                  viewBox='0 0 48 48'
                  aria-hidden='true'
                >
                  <path
                    d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                    strokeWidth={2}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
                {!isDragActive ? (
                  <>
                    <span className='cursor-pointer rounded-md text-sm font-medium text-primary-600 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500'>
                      Upload a file
                    </span>
                    <p className='text-xs text-gray-700'>or drag and drop</p>
                  </>
                ) : (
                  <p className='text-xs text-gray-500'>Drop File Here</p>
                )}
              </div>
            ) : (
              <>
                <div className='flex-1'>
                  {files?.[0]?.path?.split(".").pop() === "pdf" ? (
                    <embed
                      type='application/pdf'
                      className='object-cover object-top h-full w-full z-0'
                      src={
                        files?.[0]?.preview ||
                        (multiple
                          ? lodash.get(values, id)?.[0]
                          : lodash.get(values, id))
                      }
                    />
                  ) : (
                    <img
                      className='object-cover object-top h-full w-full z-0'
                      src={
                        files?.[0]?.preview ||
                        (multiple
                          ? lodash.get(values, id)?.[0]
                          : lodash.get(values, id))
                      }
                      alt='Hello'
                    />
                  )}
                </div>
                {(!files?.[0]?.fileUrl || !files?.[0]?.fileUrl) && (
                  <div className='p-3 absolute inset-x-0 top-0 z-50'>
                    <div
                      className={classNames(
                        files?.[0]?.progress < 100
                          ? "bg-amber-100"
                          : "bg-green-100",
                        "rounded-full"
                      )}
                    >
                      <div
                        className={classNames(
                          files?.[0]?.progress < 100
                            ? "bg-amber-400"
                            : "bg-green-400",
                          "rounded-full h-full text-white p-0.5 text-xs text-center"
                        )}
                      >
                        {files?.[0]?.progress < 100 ? (
                          <span>Uploading</span>
                        ) : (
                          <span>Uploaded</span>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          {multiple ? (
            <div className='flex-1 flex border-primary-300 border-2 border-dashed rounded-md'>
              <div className='flex-1 grid-cols-2 grid-rows-3 grid gap-3 p-2'>
                {(files.length ? files : files).map(
                  (file: any, index: number) => (
                    <div
                      key={index}
                      className={classNames(
                        "relative border  rounded flex p-2 space-x-2",
                        file?.loading ? "border-blue-500" : "",
                        file?.error ? "border-red-500" : "",
                        file?.fileUrl ? "border-green-500" : ""
                      )}
                    >
                      <div className='flex-shrink-0 w-1/4'>
                        <div className='aspect-h-1 aspect-w-1'>
                          {Object.keys(FileIconMappings).includes(
                            file?.type
                          ) ? (
                            <button
                            disabled={!file?.fileUrl}
                              className='cursor-pointer '
                              onClick={wrapClick(() =>
                                window.open(file?.fileUrl)
                              )}
                              type='button'
                            >
                              {
                                FileIconMappings[
                                  file?.type as keyof typeof FileIconMappings
                                ]
                              }
                            </button>
                          ) : (
                            <div className=' w-full h-full [&_*]:!w-full [&_*]:!h-full'>
                              {wrapImage(
                                <img
                                  className='rounded-md object-cover object-center border'
                                  src={file?.preview || file}
                                  alt='Hello'
                                />
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='flex-1 flex flex-col justify-between'>
                        <div className='flex justify-between'>
                          <div className='flex flex-col items-start '>
                            <span className='text-xs line-clamp-2 '>
                              {file?.name}
                            </span>
                            <span className='text-xs text-gray-500 font-light'>
                              {numeral(file?.size).format("0.0 b")}
                            </span>
                          </div>
                          <button
                            type='button'
                            onClick={wrapClick(removeFile(index))}
                            className='rounded-full bg-red-600 p-0.5 h-min text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600'
                          >
                            <XMarkIcon aria-hidden='true' className='h-3 w-3' />
                          </button>
                        </div>
                        <div className='flex items-center space-x-1.5'>
                          <div className='bg-primary-50 rounded-full h-2 flex-1 '>
                            <div
                              className={classNames(
                                "rounded-full w-0 origin-left  h-full transition-all",
                                file?.fileUrl ? "bg-green-500" : "",
                                file?.error ? "bg-red-500" : "",
                                file?.progress <= 100 &&
                                  !file?.error &&
                                  !file?.fileUrl
                                  ? "bg-primary-500"
                                  : ""
                              )}
                              style={{
                                width: `${file?.progress}%`,
                              }}
                            />
                          </div>
                          <div className='text-xs'>
                            {file?.fileUrl ? (
                              <CheckCircleIcon className='w-4 h-4 text-green-500 stroke-[2px]' />
                            ) : (
                              ""
                            )}
                            {file?.error ? (
                              <ExclamationCircleIcon className='w-4 h-4 text-red-500 stroke-[2px]' />
                            ) : (
                              ""
                            )}
                            {file?.progress <= 100 &&
                            !file?.error &&
                            !file?.fileUrl
                              ? `${file?.progress}%`
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {lodash.get(errors, id) && lodash.get(touched, id) ? (
        <p className='mt-2 text-sm text-red-600' id={`${id}-error`}>
          {lodash.get(errors, id)}
        </p>
      ) : null}
    </>
  );
};

export default UploadArea;
