import { gql, useQuery } from '@apollo/client';
import { SearchSelectInput } from 'components/core';
import { FC } from 'react';

interface DistrictPickerContainerProps {
  filter?: {
    region?: string;
  };
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any;
  setFieldValue: any;
  setFieldTouched?: any;
  setFieldError?: any;
  rawId?: boolean;
}

const GET_DISTRICTS = gql`
  query GetDistrictsSelect($region: ID) {
    districts: getDistricts(region: $region sort: "name",pageSize:0) {
      _id
      code
      name
    }
  }
`;

const DistrictPickerContainer: FC<DistrictPickerContainerProps> = ({ filter, id, label, rawId, ...form }) => {
  const { loading, data } = useQuery(GET_DISTRICTS, {
    variables: {
      ...(filter ? filter : {})
    },
    notifyOnNetworkStatusChange: false
  })

  return (
    <SearchSelectInput
      id={id ?? "district"}
      label={label ?? "District"}
      placeholder="Select District"
      optionsLoading={loading}
      options={(data?.districts ?? [])?.map((district: any) => ({
        label: {
          title: district.name as string
        },
        value: rawId ? district._id : district
      }))}
      {...form}
      disabled={form.disabled}
    />
  )
}

export default DistrictPickerContainer