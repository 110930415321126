import { useQuery } from "@apollo/client";
import {
  CalculatorIcon,
  CheckCircleIcon,
  EllipsisHorizontalCircleIcon,
  HashtagIcon,
  HomeModernIcon,
  PauseCircleIcon,
  QuestionMarkCircleIcon,
  TicketIcon,
  XCircleIcon
} from "@heroicons/react/24/outline";
import { Avatar, Loader, Tabs } from "components";
import { useState } from "react";
import { useMatch } from "react-location";
import { LocationGenerics } from "router/location";
import { wrapClick } from "utils";
import { GET_SERVICE_POINT } from "./apollo";
import CustomerView from "./cards/customer-view";
import {
  ServicePointComplaintHistoryContainer,
  ServicePointReadingHistoryContainer,
  ServicePointUpdateHistoryContainer,
} from "./containers";
import CreateComplaintContainer from "./create-complaint";

const orderTabs = [
  {
    name: "Customer Details",
    href: "details",
  },
  {
    name: "Update History",
    href: "update-history",
  },
  {
    name: "Reading History",
    href: "reading-history",
  },
  {
    name: "Tickets",
    href: "complaint-history",
  },
];

export default function ViewCustomerContainer({ id }: { id?: string }) {
  const { params } = useMatch<LocationGenerics>();
  const [orderTab, setOrderTab] = useState("details");
  const [createOpen, setCreateOpen] = useState(false);

  const { data, loading } = useQuery(GET_SERVICE_POINT, {
    variables: {
      id: id || params.id,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  if (loading) {
    return (
      <div className="flex-1 flex items-center justify-center">
        <Loader />
      </div>
    );
  }

  return (
    <article className="bg-white/50 overflow-y-auto relative">
      <div>
        <div>
          <img
            alt=""
            src={require("assets/background.jpg")}
            className="h-32 w-full object-cover lg:h-48 blur-sm"
          />
        </div>
        <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
          <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
            <div className="flex">
              <Avatar
                disabled={true}
                size="2xl"
                alt={
                  (
                    data?.servicePoint?.customerRepresentative?.fullName ||
                    data?.servicePoint?.customerOrganization?.name ||
                    data?.servicePoint?.customerRepresentative?.fullName
                  )?.trim() || "N A"
                }
                src={
                  data?.servicePoint?.customerRepresentative?.profileImageUrl ||
                  data?.servicePoint?.customerRepresentative?.profileImageUrl ||
                  ""
                }
              />
            </div>
            <div className="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
              <div className="mt-6 min-w-0 flex-1 sm:hidden 2xl:block">
                <h1 className="truncate text-2xl font-bold text-gray-900">
                  {(
                    data?.servicePoint?.customerRepresentative?.fullName ||
                    data?.servicePoint?.customerOrganization?.name ||
                    data?.servicePoint?.customerRepresentative?.fullName
                  )?.trim() || "N/A"}
                </h1>
                <dl className="mt-2 flex flex-row flex-wrap">
                  <dt className="sr-only">Service Point Number</dt>
                  <dd className="flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6">
                    <HomeModernIcon
                      aria-hidden="true"
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    />
                    {data?.servicePoint?.code}
                  </dd>
                  <dt className="sr-only">Account Number</dt>
                  <dd className="mt-3 flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6 sm:mt-0">
                    <HashtagIcon
                      aria-hidden="true"
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    />
                    {data?.servicePoint?.accountCode}
                  </dd>
                  <dt className="sr-only">Meter Number</dt>
                  <dd className="mt-3 flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6 sm:mt-0">
                    <CalculatorIcon
                      aria-hidden="true"
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    />
                    {data?.servicePoint?.meterCode}
                  </dd>
                  <dt className="sr-only">Account Status</dt>
                  <dd className="mt-3 flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6 sm:mt-0">
                    {data?.servicePoint?.status === "Pending" && (
                      <EllipsisHorizontalCircleIcon
                        aria-hidden="true"
                        className={"mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"}
                      />
                    )}
                    {data?.servicePoint?.status === "Active" && (
                      <CheckCircleIcon
                        aria-hidden="true"
                        className={
                          "mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                        }
                      />
                    )}
                    {data?.servicePoint?.status === "Suspended" && (
                      <PauseCircleIcon
                        aria-hidden="true"
                        className={
                          "mr-1.5 h-5 w-5 flex-shrink-0 text-amber-400"
                        }
                      />
                    )}
                    {data?.servicePoint?.status === "Terminated" && (
                      <XCircleIcon
                        aria-hidden="true"
                        className={"mr-1.5 h-5 w-5 flex-shrink-0 text-red-400"}
                      />
                    )}
                    {!data?.servicePoint?.status && (
                      <QuestionMarkCircleIcon
                        aria-hidden="true"
                        className={"mr-1.5 h-5 w-5 flex-shrink-0 text-red-400"}
                      />
                    )}
                    {data?.servicePoint?.status}
                  </dd>
                </dl>
              </div>
              <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-x-4 sm:space-y-0">
                <button
                  type="button"
                  onClick={wrapClick(() => setCreateOpen(true))}
                  className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-primary-50 hover:ring-primary-600 group"
                >
                  <TicketIcon
                    aria-hidden="true"
                    className="-ml-0.5 h-5 w-5 text-gray-400 group-hover:text-primary-600"
                  />
                  Create Ticket
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Tabs */}
      <div className="mt-6 sm:mt-2 2xl:mt-5 sticky top-0">
        <Tabs tabs={orderTabs} onTabChanged={setOrderTab} />
      </div>
      <div className="p-6">
        {orderTab === "details" && (
          <CustomerView servicePoint={data?.servicePoint} />
        )}
        {orderTab === "update-history" && (
          <ServicePointUpdateHistoryContainer
            servicePoint={data?.servicePoint?._id}
          />
        )}
        {orderTab === "reading-history" && (
          <ServicePointReadingHistoryContainer
            servicePoint={data?.servicePoint?._id}
          />
        )}
        {orderTab === "complaint-history" && (
          <ServicePointComplaintHistoryContainer
            servicePoint={data?.servicePoint?._id}
          />
        )}
      </div>
      {data?.servicePoint?._id && (
        <CreateComplaintContainer
          open={createOpen}
          setOpen={setCreateOpen}
          servicePoint={data?.servicePoint}
        />
      )}
    </article>
  );
}
