import moment from "moment";
import { FC } from "react";

interface EnquiryViewProps {
  enquiry: any;
}

const EnquiryView: FC<EnquiryViewProps> = ({ enquiry }) => {
  return (
    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3 p-6">
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Ticket #</dt>
        <dd className="mt-1 text-sm text-gray-900">{enquiry?.code}</dd>
      </div>
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Type</dt>
        <dd className="mt-1 text-sm text-gray-900">{enquiry?.type?.name}</dd>
      </div>
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Opened At</dt>
        <dd className="mt-1 text-sm text-gray-900">
          {moment(enquiry?.createdAt).format("DD/MM/YYYY hh:mm A")}
        </dd>
      </div>

      <div className="!col-start-1 sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Contact Name</dt>
        <dd className="mt-1 text-sm text-gray-900">
          {enquiry?.contactPerson?.fullName || "N/A"}
        </dd>
      </div>
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Contact Phone</dt>
        <dd className="mt-1 text-sm text-gray-900">
          {enquiry?.contactPerson?.phoneNumber || "N/A"}
        </dd>
      </div>
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Contact Email</dt>
        <dd className="mt-1 text-sm text-gray-900">
          {enquiry?.contactPerson?.emailAddress || "N/A"}
        </dd>
      </div>
    </dl>
  );
};

export default EnquiryView;
