/* eslint-disable no-template-curly-in-string */
import type { FC } from "react";
// import ViewDisconnectionServiceOrderContainer from "../disconnection-service-orders/view";
// import ViewFaultServiceOrderContainer from "../fault-service-orders/view";
// import ViewInvestigationServiceOrderContainer from "../investigation-service-orders/view";
// import ViewReconnectionServiceOrderContainer from "../reconnection-service-orders/view";
// import ViewReplacementServiceOrderContainer from "../replacement-service-orders/view";
// import ViewTerminationServiceOrderContainer from "../termination-service-orders/view";

interface ServiceOrderContainerProps {
  serviceOrderType: string;
  serviceOrder: {
    _id: string;
    code: string;
    location: {
      address: string;
      community: string;
    };
    replacementStatus?: string;
    investigationStatus?: string;
    terminationStatus?: string;
    faultStatus?: string;
    disconnectionStatus?: string;
    reconnectionStatus?: string;
    updatedAt: string;
  };
  open: boolean;
  close: () => void;
}

const ServiceOrderContainer: FC<ServiceOrderContainerProps> = ({
  serviceOrder,
  serviceOrderType,
  open,
  close
}) => {
  return <></>
  // switch (serviceOrderType) {
  //   case "ReplacementServiceOrder": {
  //     return (
  //       <ViewReplacementServiceOrderContainer
  //         open={open}
  //         close={close}
  //         id={serviceOrder._id}
  //       />
  //     );
  //   }
  //   case "InvestigationServiceOrder": {
  //     return (
  //       <ViewInvestigationServiceOrderContainer
  //         open={open}
  //         close={close}
  //         id={serviceOrder._id}
  //       />
  //     );
  //   }
  //   case "DisconnectionServiceOrder": {
  //     return (
  //       <ViewDisconnectionServiceOrderContainer
  //         open={open}
  //         close={close}
  //         id={serviceOrder._id}
  //       />
  //     );
  //   }
  //   case "FaultServiceOrder": {
  //     return (
  //       <ViewFaultServiceOrderContainer
  //         open={open}
  //         close={close}
  //         id={serviceOrder._id}
  //       />
  //     );
  //   }
  //   case "ReconnectionServiceOrder": {
  //     return (
  //       <ViewReconnectionServiceOrderContainer
  //         open={open}
  //         close={close}
  //         id={serviceOrder._id}
  //       />
  //     );
  //   }
  //   case "TerminationServiceOrder": {
  //     return (
  //       <ViewTerminationServiceOrderContainer
  //         open={open}
  //         close={close}
  //         id={serviceOrder._id}
  //       />
  //     );
  //   }
  // }
};

export default ServiceOrderContainer;
