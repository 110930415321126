import { useReactiveVar } from "@apollo/client";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import {
  AcademicCapIcon,
  ArrowRightOnRectangleIcon,
  BellIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";
import { clearAuth, currentTokenVar, currentUserVar } from "apollo/cache/auth";
import { Avatar } from "components/core";
import _ from "lodash";
import { FC } from "react";
import { Link, Navigate, Outlet } from "react-location";
import "react-tooltip/dist/react-tooltip.css";
import { withRoutePermissions } from "router/filter";
import routes from "router/routes";
import { classNames, wrapClick } from "utils";

const AppLayout: FC = () => {
  const currentUser = useReactiveVar(currentUserVar);
  const currentToken = useReactiveVar(currentTokenVar);

  const navigation = _.chain(routes)
    .filter((route) => _.get(route, "meta.layout") === "App")
    .thru(withRoutePermissions)
    .filter((item) => !!item.sidebar)
    .value();

  if (!currentToken) {
    // if (window.location.hostname.includes("ecg.com.gh")) {
    //   window.location.href = "https://staff.ecg.com.gh";
    // } else {
      return (
        <Navigate
          to={"/signin"}
          // search={{ redirect: location.current.href }}
          replace
        />
      );
    // }
  }

  return (
    <div className="h-screen">
      <div className="h-full flex bg-primary-100">
        {/* Static sidebar for desktop */}
        <div className="hidden lg:flex lg:flex-shrink-0">
          <div className="flex flex-col w-18">
            <div className="flex flex-shrink-0 items-center p-4 flex-row">
              <div className="flex-shrink-0 h-10 w-10 p-1 bg-primary-600 rounded-md">
                <img
                  className="h-full w-full aspect-square border-white border p-1 rounded-md"
                  src={require("assets/logo.png")}
                  alt="SUBS"
                />
              </div>
            </div>
            <div className="py-4 flex flex-grow flex-col overflow-y-auto no-scrollbar">
              <nav
                className="flex-1 space-y-1.5 px-2 flex flex-col items-center"
                aria-label="Sidebar"
              >
                {_.map(navigation, (item, idx) => {
                  return (
                    <Link
                      key={item.sidebar?.label}
                      to={item.path}
                      data-tooltip-content={item.sidebar?.label}
                      data-tooltip-id={`sidebar-link-${idx}`}
                      data-tooltip-delay-show={500}
                      getActiveProps={() => ({
                        className: "bg-primary-600 text-primary-100 w-fit",
                      })}
                      getInactiveProps={() => ({
                        className:
                          "text-primary-700 hover:bg-primary-700 hover:text-primary-200",
                      })}
                      className="group flex items-center px-2 py-2 text-sm rounded-md"
                    >
                      {item.sidebar?.icon && (
                        <item.sidebar.icon
                          className={classNames(
                            // "text-primary-700 group-hover:text-primary-200",
                            "flex-shrink-0 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                      )}
                      {/* {item.sidebar?.label} */}
                    </Link>
                  );
                })}
              </nav>
              <nav className="space-y-1.5 px-2 flex flex-col items-center">
                <a
                  href="mailto:support.cra@polymorphlabs.io"
                  target="_blank"
                  className="group flex items-center px-2 py-2 text-sm rounded-md text-gray-300 hover:bg-primary-700 hover:text-primary-500"
                  rel="noreferrer"
                >
                  <EnvelopeIcon
                    className={classNames(
                      "text-primary-700 group-hover:text-primary-200",
                      "flex-shrink-0 h-6 w-6"
                    )}
                    aria-hidden="true"
                  />
                </a>
                <a
                  href="https://docs.subsenterprise.com/commercial-services-console"
                  target="_blank"
                  className="group flex items-center px-2 py-2 text-sm rounded-md text-gray-300 hover:bg-primary-700 hover:text-primary-500"
                  rel="noreferrer"
                >
                  <AcademicCapIcon
                    className={classNames(
                      "text-primary-700 group-hover:text-primary-200",
                      "flex-shrink-0 h-6 w-6"
                    )}
                    aria-hidden="true"
                  />
                </a>
                <button
                  onClick={wrapClick(clearAuth)}
                  className="group flex items-center px-2 py-2 text-sm rounded-md text-gray-300 hover:bg-primary-700 hover:text-primary-500"
                >
                  <ArrowRightOnRectangleIcon
                    className={classNames(
                      "text-primary-700 group-hover:text-primary-200",
                      "flex-shrink-0 h-6 w-6"
                    )}
                    aria-hidden="true"
                  />
                </button>
              </nav>
            </div>

            <div className="flex flex-shrink-0 p-4 overflow-hidden">
              <div className="flex-1 flex items-center space-x-3 overflow-hidden">
                <div className="flex-shrink-0">
                  <Avatar
                    src={currentUser?.profileImageUrl}
                    alt={
                      [
                        currentUser?.firstName || "",
                        currentUser?.lastName || "",
                      ]
                        .join(" ")
                        .trim() || "N A"
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 min-w-0 flex flex-col overflow-hidden">
          <div className="px-4">
            <div className="flex h-14 justify-between">
              <div className="flex" />
              <div className="flex items-center">
                <div className="hidden md:ml-4 md:flex md:flex-shrink-0 md:items-center">
                  <button
                    type="button"
                    className="relative rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  >
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">View notifications</span>
                    <BellIcon aria-hidden="true" className="h-6 w-6" />
                  </button>

                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <MenuButton className="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Open user menu</span>
                        <Avatar
                          src={currentUser?.profileImageUrl}
                          size="xs"
                          alt={
                            [
                              currentUser?.firstName || "",
                              currentUser?.lastName || "",
                            ]
                              .join(" ")
                              .trim() || "N A"
                          }
                        />
                      </MenuButton>
                    </div>
                    <MenuItems
                      transition
                      className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                    >
                      <MenuItem>
                        <a
                          href="/"
                          className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100"
                        >
                          Your Profile
                        </a>
                      </MenuItem>
                      <MenuItem>
                        <a
                          href="/"
                          className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100"
                        >
                          Settings
                        </a>
                      </MenuItem>
                      <MenuItem>
                        <a
                          href="/"
                          className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100"
                        >
                          Sign out
                        </a>
                      </MenuItem>
                    </MenuItems>
                  </Menu>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 min-w-0 flex flex-col overflow-hidden mr-2 mb-2 rounded-md border border-black/10 shadow-md">
            {/* Display content here */}
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
