import lodash from "lodash";
import { FC, useState } from "react";
import { classNames, wrapClick } from "utils";

export type Tab = {
  name: string;
  href: string;
  activeStatues?: string[];
  disabled?: boolean;
};

interface TabsProps {
  tabs: Tab[];
  status?: string;
  onTabChanged?: (tab: string) => void;
}

export const Tabs: FC<TabsProps> = ({ tabs, status, onTabChanged }) => {
  const [tab, setTab] = useState(lodash.first(tabs)?.href || "");

  const __setTab = (tab: string) => () => {
    setTab(tab);
    onTabChanged?.(tab);
  };

  return (
    <div className="block">
      <div className="border-b border-gray-200 bg-white px-6">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          {tabs.map((_tab) => (
            <button
              key={_tab.name}
              onClick={wrapClick(__setTab(_tab.href))}
              disabled={
                _tab?.disabled || (status && 
                !_tab?.activeStatues?.includes(
                  status
                ) ? true : false)
              }
              className={classNames(
                tab === _tab.href
                  ? "border-primary-500 text-primary-600"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                "whitespace-nowrap disabled:cursor-not-allowed flex py-4 px-1 border-b-2 font-medium text-sm"
              )}
              aria-current={tab === _tab.href ? "page" : undefined}
            >
              {_tab.name}
            </button>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default Tabs;
