
import { gql, useQuery } from '@apollo/client';
import { ResponsivePie } from '@nivo/pie';
import _ from 'lodash';
import { FC } from 'react';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';

export const GET_COMPLAINT_SUMMARY = gql`
  query GetComplaintSummary(
    $region: ID,
    $district: ID, 
    $userRole: ID ,
    $fromDate: Date
    $toDate: Date
  ) {
    summary: getComplaintSummary(
      region: $region
      district: $district
      userRole: $userRole
      fromDate: $fromDate
      toDate: $toDate
    ) {
      Open
      InProgress
      Closed
    }
  }
`;

interface PieData {
  id: string;
  label: string;
  value: number;
  color: string; 
}

const CustomersByGroupContainer: FC<{ groupBy: string }> = ({ groupBy }) => {

  const searchParams = useSearch<LocationGenerics>()
  const { data } = useQuery(GET_COMPLAINT_SUMMARY, {
    variables: {
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      fromDate: searchParams.fromDate || undefined,
      toDate: searchParams.toDate || undefined,
      groupBy
    }
  });

  const colors = [ "red", "yellow", "blue"]

  const pieData: PieData[] = _.keys(_.omit(data?.summary, ["__typename"])).map((group, idx) => ({
    id: _.startCase(group),
    label: _.startCase(group),
    value: data?.summary[group],
    color: colors[idx]

  }))

  return (
    <>
      <div className='flex flex-col gap-4 overflow-hidden rounded-lg bg-white p-4 shadow sm:p-6 h-[375px]'>
        <p className=" truncate text-sm font-medium text-primary-700">All Tickets By {_.startCase(groupBy)}</p>
        <div className='grid place-items-center'>
          <div className='h-[275px] w-[255px] '>
            <ResponsivePie
              data={pieData}
              margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
              innerRadius={0.6}
              cornerRadius={0}
              activeOuterRadiusOffset={0}
              borderWidth={1}
              borderColor={{
                from: 'color',
                modifiers: [
                  [
                    'darker',
                    0.2
                  ]
                ]
              }}
              colors={{ scheme: 'category10'}} 
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={"white"}
              layers={[ 'arcs', 'arcLabels', 'legends']}
              
            />

          </div>
        </div>
      </div>
    </>
  )
}

export default CustomersByGroupContainer;
