import { gql, useQuery } from "@apollo/client";
import {
  CursorArrowRaysIcon,
  EnvelopeOpenIcon,
  UsersIcon
} from "@heroicons/react/24/outline";
import { default as _, default as lodash } from "lodash";
import numeral from "numeral";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { classNames } from "utils";
import CustomersByGroupContainer from './customers-by-group';

const GET_SUPPORT_SUMMARY = gql`
  query getSupportSummary(
    $region: ID, 
    $district: ID
    $userRole: ID
    $fromDate: Date
    $toDate: Date
  ) {
    summary: getSupportSummary(
      district: $district, 
      region: $region
      userRole: $userRole
      fromDate: $fromDate
      toDate: $toDate
    ) {
      Overdue
      DueToday
      Open
      OnHold
      Unassigned
      AssignedToMe
      AllTickets
    }
}

`;

const ticketStats = [
  {
    id: 1,
    name: "Overdue Tickets",
    icon: UsersIcon,
    accessor: "Overdue",
    emphasize: true
  },
  {
    id: 2,
    name: "Tickets Due Today",
    icon: EnvelopeOpenIcon,
    accessor: "DueToday",
    emphasize: false
  },
  {
    id: 3,
    name: "Open Tickets",
    icon: CursorArrowRaysIcon,
    accessor: "Open",
    emphasize: true
  },
  {
    id: 4,
    name: "Tickets On Hold",
    icon: CursorArrowRaysIcon,
    accessor: "OnHold",
    emphasize: false
  },
  {
    id: 5,
    name: "Unassigned Tickets",
    icon: CursorArrowRaysIcon,
    accessor: "Unassigned",
    emphasize: true
  },
  {
    id: 6,
    name: "Tickets I'm Watching",
    icon: CursorArrowRaysIcon,
    accessor: "AssignedToMe",
    emphasize: false
  },
];


function StatBar({ summaryData, accessor, total }: {
  summaryData: any, 
  accessor: string,
  total: number,
}){

  return (
    <>
      <div className='flex flex-col gap-2'>
        <div className='flex justify-between gap-2 items-center'>
          <p className='text-sm font-normal text-gray-500'>{_.startCase(accessor)}</p>
          <p className='text-sm font-normal text-gray-500'>{_.get(summaryData, accessor) ?? "N/A"}</p>
        </div>
        <div className='relative w-full h-2'>
          <div className='absolute w-full top-0 left-0 bg-gray-200 rounded-md h-full'></div>
          <div className='absolute top-0 left-0 bg-blue-500 rounded-md h-full'
            style={{ width: Math.round((_.get(summaryData, accessor)/(total === 0 ? 1 : total) ) * 100) + "%" }}>
            </div>
        </div>

      </div>
    </>
  )

}


const TicketsOverviewContainer = () => {
  const searchParams = useSearch<LocationGenerics>();


  const { data } = useQuery(GET_SUPPORT_SUMMARY, {
    variables: {
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      fromDate: searchParams.fromDate || undefined,
      toDate: searchParams.toDate || undefined,
    },
  });

  return (
    <>
      <div className='col-span-12'>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3 lg:grid-cols-6">
          {ticketStats.map((item) => (
            <div
              key={item.id}
              className="flex items-center space-x-6 overflow-hidden rounded-lg bg-white p-4 shadow sm:p-6"
            >
              <div className="flex-1">
                <dt>
                  <p className=" truncate text-sm font-medium text-primary-700">
                    {item.name}
                  </p>
                </dt>
                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                  <div className={
                    classNames(
                      `flex items-baseline text-2xl font-semibold`,
                      item.emphasize ? "text-red-600" : "text-primary-700"
                    )}>
                    {numeral(
                      lodash.get(
                        data?.summary,
                        item.accessor,
                        0
                      )
                    ).format("0,0")}
                  </div>
                </dd>
              </div>
            </div>
          ))}
        </dl>
      </div>
      <div className='col-span-4'>
        <CustomersByGroupContainer
          groupBy='Priority'
        />
      </div>

      <div className='col-span-4'>
        <CustomersByGroupContainer
          groupBy='Status'
        />
      </div>
      <div className='col-span-4 h-[375px]'>
        <div className='flex flex-col gap-2 overflow-hidden rounded-lg bg-white p-4 shadow sm:p-6 h-full'>
          <p className=" truncate text-sm font-medium text-primary-700">All Tickets By Status</p>
          <div className='flex flex-col gap-4'>
            {_.keys(_.omit(data?.summary, ["AllTickets", "__typename"])).map((accessor, idx) => (
              <StatBar
                summaryData={_.omit(data?.summary, ["AllTickets", "__typename"])}
                accessor={accessor}
                total={_.get(data?.summary, "AllTickets")}
                key={idx}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default TicketsOverviewContainer;
