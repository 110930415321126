import { FC, useEffect } from "react";
import { Link, Outlet, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import { useUrlState, wrapOnchange } from "utils";

import { useLazyQuery } from "@apollo/client";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Avatar, Loader } from "components";
import TableEmptyComponent from "components/tables/table-empty";
import numeral from "numeral";
import { GET_SERVICE_POINTS } from "./apollo";

const CustomersPage: FC = () => {
  const navigate = useNavigate<LocationGenerics>();
  const [search, setSearch] = useUrlState("search");

  const [getServicePoints, { data, loading }] =
    useLazyQuery(GET_SERVICE_POINTS);

  const onSearch = () => {
    getServicePoints({
      variables: {
        page: 0,
        pageSize: 0,
        sort: "",
        search: search.toString() || undefined,
        searchFields: [
          "code",
          "customerCode",
          "accountCode",
          "meterCode",
          "customerRepresentative.fullName",
          "customerRepresentative.phoneNumber",
        ],
      },
    });
  };

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "" } : {}),
      }),
    });
  }, [navigate]);

  return (
    <main className="flex-1 flex flex-col overflow-hidden h-screen bg-gray-50">
      <div className="relative z-0 flex flex-1 overflow-hidden">
        <main className="relative z-0 flex-1 flex flex-col overflow-hidden focus:outline-none xl:order-last">
          <Outlet />
        </main>
        <aside className="bg-white w-96 flex-shrink-0 border-r border-gray-200 divide-y divide-gray-200 order-first flex flex-col">
          <div className="px-6 pb-4 pt-6">
            <h2 className="text-lg font-medium text-gray-900">Customers</h2>
            <p className="mt-1 text-sm text-gray-600">
              {data?.rows?.length
                ? `Showing results of ${numeral(data?.count).format(
                    "0,0",
                  )} customers`
                : "Search directory of customers"}
            </p>
            <form
              onSubmit={wrapOnchange(onSearch)}
              className="mt-6 flex gap-x-4"
            >
              <div className="min-w-0 flex-1">
                <label htmlFor="search" className="sr-only">
                  Search
                </label>
                <div className="relative rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <MagnifyingGlassIcon
                      aria-hidden="true"
                      className="h-5 w-5 text-gray-400"
                    />
                  </div>
                  <input
                    id="search"
                    name="search"
                    type="search"
                    placeholder="Search"
                    className="block w-full rounded-md border-0 py-1.5 pl-10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-6"
                    onChange={wrapOnchange(setSearch)}
                    value={search}
                  />
                </div>
              </div>
              <button
                type="submit"
                className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                <MagnifyingGlassIcon aria-hidden="true" className="h-5 w-5" />
                <span className="sr-only">Search</span>
              </button>
            </form>
          </div>
          {/* Directory list */}
          {loading ? (
            <div className="flex items-center justify-center flex-1">
              <Loader title="Searching Customers..." />
            </div>
          ) : data?.rows?.length ? (
            <nav
              aria-label="Directory"
              className="min-h-0 flex-1 overflow-y-auto"
            >
              <ul className="relative z-0 divide-y divide-gray-200">
                {data?.rows?.map((item: any) => (
                  <li key={item._id}>
                    <Link
                      to={`./${item?._id}`}
                      className="relative flex items-center space-x-3 px-6 py-5 hover:bg-gray-50"
                      getActiveProps={() => ({
                        className:
                          "border-r-2 border-inset border-primary-500 bg-primary-50",
                      })}
                    >
                      <div className="flex-shrink-0">
                        <Avatar
                          disabled={true}
                          alt={
                            (
                              item?.customerRepresentative?.fullName ||
                              item?.customerOrganization?.name ||
                              item?.customerRepresentative?.fullName
                            )?.trim() || "N A"
                          }
                          src={
                            item?.customerRepresentative?.profileImageUrl ||
                            item?.customerRepresentative?.profileImageUrl ||
                            ""
                          }
                        />
                      </div>
                      <div className="min-w-0 flex-1">
                        <div className="focus:outline-none">
                          {/* Extend touch target to entire panel */}
                          <span
                            aria-hidden="true"
                            className="absolute inset-0"
                          />
                          <p className="text-sm font-medium text-gray-900">
                            {(
                              item?.customerRepresentative?.fullName ||
                              item?.customerOrganization?.name ||
                              item?.customerRepresentative?.fullName
                            )?.trim() || "N/A"}
                          </p>
                          <p className="truncate text-sm text-gray-500">
                            {item?.code}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          ) : (
            <div className="flex items-center justify-center flex-1">
              <TableEmptyComponent title="Customer Match Search" />
            </div>
          )}
        </aside>
      </div>
    </main>
  );
};

export default CustomersPage;
