import { UserIcon } from "@heroicons/react/20/solid";
import { useFormik } from "formik";
import parse from "html-react-parser";
import lodash from "lodash";
import { FC, useMemo, useState } from "react";
import {
  classNames,
  displayUserName,
  wrapClick,
  wrapOnchange,
} from "./../../utils";
import { Complaint } from "./schema";

import { useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";
import { RadioGroup } from "@headlessui/react";
import { Avatar, RichInput } from "components";
import moment from "moment";
import PulseLoader from "react-spinners/PulseLoader";
import {
  CLOSE_COMPLAINT,
  COMPLETE_COMPLAINT_SERVICE_ORDER,
  CREATE_COMPLAINT_SERVICE_ORDER,
  ESCALATE_COMPLAINT,
  GET_COMPLAINT_USERS,
  UPDATE_COMPLAINT_STAGE,
} from "./queries";
import ServiceOrderContainer from "./service-order";
import { actionsMap } from "./timeline";
import { currentUserVar } from "apollo/cache/auth";

interface EscalateCardProps {
  title?: string;
  question?: string;
  onSelect?: (id: string) => void;
  loading: boolean;
}

const EscalateCard: FC<EscalateCardProps> = ({
  title,
  question,
  onSelect,
  loading,
}) => {
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState("");

  const [getUsers, { data, loading: loadingUsers, called }] =
    useLazyQuery(GET_COMPLAINT_USERS);

  const onSearch = () => {
    getUsers({
      variables: {
        page: 0,
        pageSize: 12,
        sort: "code",
        search: search,
        searchFields: [
          "code",
          "firstName",
          "lastName",
          "emailAddress",
          "phoneNumber",
        ],
      },
    });
  };

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          {title}
        </h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>{question}</p>
        </div>
        <form
          className="mt-5 sm:flex sm:items-center"
          onSubmit={wrapClick(onSearch)}
        >
          <div className="w-full sm:max-w-xs">
            <label htmlFor="search" className="sr-only">
              Name or Staff ID
            </label>
            <input
              id="search"
              name="search"
              type="text"
              placeholder="eg 213295 or Kofi Mensah"
              onChange={wrapOnchange(setSearch)}
              value={search}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
            />
          </div>
          <button
            type="submit"
            className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 sm:ml-3 sm:mt-0 sm:w-auto"
          >
            Search
          </button>
        </form>
        {loadingUsers ? (
          <div className="mt-5 space-y-3">loading...</div>
        ) : data?.users?.length ? (
          <div className="mt-5 space-y-3 max-h-96 overflow-y-auto">
            {data?.users?.map((user: any) => (
              <div
                key={user._id}
                className="rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-center space-x-6"
              >
                <div className="flex-shrink-0 rounded-full ring-8 ring-white">
                  <Avatar
                    alt={displayUserName(user).trim() || "N A"}
                    src={user?.profileImageUrl || ""}
                  />
                </div>
                <div className="flex-1">
                  <div className="text-sm font-medium text-gray-900">
                    {displayUserName(user)}
                  </div>
                  <div className="mt-1 text-sm text-gray-600 sm:flex sm:items-center">
                    <div>{user?.code}</div>
                    <span
                      aria-hidden="true"
                      className="hidden sm:mx-2 sm:inline"
                    >
                      &middot;
                    </span>
                    <div className="mt-1 sm:mt-0">
                      {user?.roleName || "N/A"}
                    </div>
                  </div>
                </div>
                <div className="flex-shrink-0">
                  <button
                    type="button"
                    disabled={loading}
                    onClick={wrapClick(() => {
                      setSelected(user?._id);
                      onSelect?.(user._id);
                    })}
                    className={classNames(
                      loading
                        ? "cursor-not-allowed bg-gray-50"
                        : "cursor-pointer bg-white hover:bg-gray-50",
                      "inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300",
                    )}
                  >
                    Escalate
                    {loading && selected === user._id && (
                      <PulseLoader
                        color={"#111827"}
                        loading={true}
                        cssOverride={{
                          marginLeft: "8px",
                        }}
                        size={5}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    )}
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : called ? (
          <div className="mt-5 space-y-3 text-center">
            <div className="text-sm font-medium text-gray-900">
              No Users Match Term Specified
            </div>
            <div className="text-sm font-normal text-gray-700">
              Double check your input
            </div>{" "}
          </div>
        ) : null}
      </div>
    </div>
  );
};

interface QuestionCardProps {
  title: string;
  question?: string;
  options?: {
    label: string;
    description?: string;
    value: string;
  }[];
  onSelect?: (option: any) => void;
  loading: boolean;
}

const QuestionCard: FC<QuestionCardProps> = ({
  title,
  question,
  options,
  onSelect,
  loading,
}) => {
  const [selected, setSelected] = useState("");

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          {title}
        </h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>{question}</p>
        </div>
        <RadioGroup
          value={selected}
          onChange={(value) => {
            setSelected(value);
            onSelect?.(value);
          }}
          className="mt-5 grid grid-cols-2 gap-3"
        >
          {(selected
            ? lodash.filter(options, ["value", selected])
            : options
          )?.map((option, idx) => (
            <RadioGroup.Option
              key={idx}
              value={option.value}
              disabled={!!selected || loading}
              className={classNames(
                !!selected || loading
                  ? "cursor-not-allowed bg-primary-500 text-gray-50"
                  : "cursor-pointer bg-primary-600 text-white hover:bg-primary-500 hover:text-gray-50",
                "flex items-center justify-center rounded-md px-3 py-3 text-sm font-light outline-none ring-0 sm:flex-1",
              )}
            >
              {option.label}
              {loading && option.value === selected && (
                <PulseLoader
                  color={"#FFFFFF"}
                  loading={true}
                  cssOverride={{
                    marginLeft: "8px",
                  }}
                  size={10}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              )}
            </RadioGroup.Option>
          ))}
        </RadioGroup>
      </div>
    </div>
  );
};

interface NotesCardProps extends QuestionCardProps {
  notesQuestion: string;
  submitLabel?: string;
  onSubmit?: (value: any) => void;
  selectedOption?: string;
}

const NotesCard: FC<NotesCardProps> = ({
  title,
  question,
  options,
  notesQuestion,
  submitLabel,
  onSubmit,
  onSelect,
  loading,
  selectedOption,
}) => {
  const form = useFormik({
    initialValues: {
      notes: "",
      supportingDocumentUrls: [],
      selected: "",
    },
    onSubmit: async (values) => {
      await onSubmit?.(values);
      form.resetForm();
    },
  });

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          {title}
        </h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>{question}</p>
        </div>
        <RadioGroup
          value={form.values.selected}
          onChange={(value) => {
            form.setFieldValue("selected", value);
            onSelect?.(value);
          }}
          className="mt-5 grid grid-cols-2 gap-3"
        >
          {(form.values.selected
            ? lodash.filter(options, ["value", form.values.selected])
            : options
          )?.map((option, idx) => (
            <RadioGroup.Option
              key={idx}
              value={option.value}
              disabled={!!form.values.selected || loading}
              className={classNames(
                !!form.values.selected || loading
                  ? "cursor-not-allowed bg-primary-500 text-gray-50"
                  : "cursor-pointer bg-primary-600 text-white hover:bg-primary-500 hover:text-gray-50",
                "flex items-center justify-center rounded-md px-3 py-3 text-sm font-light outline-none ring-0 sm:flex-1",
              )}
            >
              {option.label}
              {loading && option.value === form.values.selected && (
                <PulseLoader
                  color={"#FFFFFF"}
                  loading={true}
                  cssOverride={{
                    marginLeft: "8px",
                  }}
                  size={10}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              )}
            </RadioGroup.Option>
          ))}
        </RadioGroup>

        {selectedOption && (
          <>
            <div className="mt-8 max-w-xl text-sm text-gray-500">
              <p>{notesQuestion}</p>
            </div>
            <form className="mt-5 space-y-6" onSubmit={form.handleSubmit}>
              <div className="w-full">
                <label htmlFor="notes" className="sr-only">
                  Notes
                </label>
                <RichInput
                  id="notes"
                  label=""
                  placeholder="e.g. I corrected the customer's bill"
                  {...form}
                />
              </div>
              <button
                type="submit"
                disabled={form.isSubmitting}
                className={classNames(
                  form.isSubmitting
                    ? "bg-primary-500 cursor-not-allowed"
                    : "bg-primary-600",
                  "inline-flex w-full items-center justify-center rounded-md px-6 py-3 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 sm:w-auto",
                )}
              >
                {submitLabel || "Submit"}
                {form.isSubmitting && (
                  <PulseLoader
                    color={"#FFFFFF"}
                    loading={true}
                    cssOverride={{
                      marginLeft: "8px",
                    }}
                    size={5}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                )}
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

interface OptionsCardProps extends QuestionCardProps {}

const OptionsCard: FC<OptionsCardProps> = ({
  title,
  question,
  options,
  onSelect,
  loading,
}) => {
  const [selected, setSelected] = useState("");

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          {title}
        </h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>{question}</p>
        </div>
        <div className="mt-5 space-y-3">
          {options?.map((option, idx) => (
            <div
              key={idx}
              className="rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-center space-x-6"
            >
              <div className="flex-shrink-0">
                <span
                  className={classNames(
                    "bg-gray-400",
                    "flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white",
                  )}
                >
                  <UserIcon aria-hidden="true" className="h-5 w-5 text-white" />
                </span>
              </div>
              <div className="flex-1">
                <div className="text-sm font-medium text-gray-900">
                  {option.label}
                </div>
                <div className="mt-1 text-sm text-gray-600 sm:flex sm:items-center">
                  <div>{option.description}</div>
                </div>
              </div>
              <div className="flex-shrink-0">
                <button
                  type="button"
                  disabled={loading}
                  onClick={wrapClick(() => {
                    setSelected(option?.value);
                    onSelect?.(option.value);
                  })}
                  className={classNames(
                    loading
                      ? "cursor-not-allowed bg-gray-50"
                      : "cursor-pointer bg-white hover:bg-gray-50",
                    "inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300",
                  )}
                >
                  Initiate
                  {loading && selected === option.value && (
                    <PulseLoader
                      color={"#111827"}
                      loading={true}
                      cssOverride={{
                        marginLeft: "8px",
                      }}
                      size={5}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  )}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

interface ServiceOrderCardProps extends NotesCardProps {
  currentStage: Complaint["currentStage"];
}

const ServiceOrderCard: FC<ServiceOrderCardProps> = ({
  title,
  question,
  notesQuestion,
  onSubmit,
  submitLabel,
  currentStage,
}) => {
  const [viewOrderOpen, setViewOrderOpen] = useState(false);
  const form = useFormik({
    initialValues: {
      notes: "",
      supportingDocumentUrls: [],
    },
    onSubmit: (values) => {
      onSubmit?.(values);
      form.resetForm();
    },
  });

  const status = useMemo(
    () =>
      currentStage.serviceOrder?.disconnectionStatus ??
      currentStage.serviceOrder?.faultStatus ??
      currentStage.serviceOrder?.investigationStatus ??
      currentStage.serviceOrder?.terminationStatus ??
      currentStage.serviceOrder?.replacementStatus ??
      currentStage.serviceOrder?.reconnectionStatus,
    [currentStage.serviceOrder],
  );

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          {title}
        </h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>
            {status === "Completed"
              ? `The ${lodash
                  .startCase(currentStage.serviceOrderType)
                  .toLowerCase()} has been completed successfully`
              : `There is a ${lodash
                  .startCase(currentStage.serviceOrderType)
                  .toLowerCase()} in progress`}
          </p>
        </div>
        <div className="mt-5 space-y-3">
          <div className="rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-center space-x-6">
            <div className="flex-shrink-0">
              <span
                className={classNames(
                  "bg-gray-400",
                  "flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white",
                )}
              >
                <UserIcon aria-hidden="true" className="h-5 w-5 text-white" />
              </span>
            </div>
            <div className="flex-1">
              <div className="text-sm font-medium text-gray-900 flex">
                #{currentStage.serviceOrder?.code}
                <span aria-hidden="true" className="hidden sm:mx-2 sm:inline">
                  &middot;
                </span>
                <div className="text-gray-600">{status}</div>
              </div>
              <div className="mt-1 text-sm text-gray-600 sm:flex sm:items-center">
                <div className="mt-1 sm:mt-0">
                  Last updated on{" "}
                  {moment(currentStage.serviceOrder?.updatedAt).format(
                    "DD/MM/YYYY [at] hh:mm A",
                  )}
                </div>
              </div>
            </div>
            <div className="flex-shrink-0">
              <button
                type="button"
                onClick={wrapClick(() => setViewOrderOpen(true))}
                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                View
              </button>
            </div>
          </div>
        </div>
        {status === "Completed" && (
          <>
            <div className="mt-8 max-w-xl text-sm text-gray-500">
              <p>{notesQuestion}</p>
            </div>
            <form className="mt-5 space-y-6" onSubmit={form.handleSubmit}>
              <div className="w-full">
                <label htmlFor="notes" className="sr-only">
                  Notes
                </label>
                <RichInput
                  id="notes"
                  label=""
                  placeholder="e.g. I corrected the customer's bill"
                  {...form}
                />
              </div>
              <button
                type="submit"
                disabled={form.isSubmitting}
                className={classNames(
                  form.isSubmitting
                    ? "bg-primary-500 cursor-not-allowed"
                    : "bg-primary-600",
                  "inline-flex w-full items-center justify-center rounded-md px-6 py-3 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 sm:w-auto",
                )}
              >
                {submitLabel || "Submit"}
                {form.isSubmitting && (
                  <PulseLoader
                    color={"#FFFFFF"}
                    loading={true}
                    cssOverride={{
                      marginLeft: "8px",
                    }}
                    size={10}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                )}
              </button>
            </form>
          </>
        )}
      </div>
      {currentStage.serviceOrder && (
        <ServiceOrderContainer
          open={viewOrderOpen}
          close={() => {
            setViewOrderOpen(false);
          }}
          serviceOrderType={currentStage.serviceOrderType}
          serviceOrder={currentStage.serviceOrder}
        />
      )}
    </div>
  );
};

interface ProgressCardProps {
  currentStage: Complaint["currentStage"];
}

const ProgressCard: FC<ProgressCardProps> = ({ currentStage }) => {
  const [viewOrderOpen, setViewOrderOpen] = useState(false);

  const status = useMemo(
    () =>
      currentStage.serviceOrder?.disconnectionStatus ??
      currentStage.serviceOrder?.faultStatus ??
      currentStage.serviceOrder?.investigationStatus ??
      currentStage.serviceOrder?.terminationStatus ??
      currentStage.serviceOrder?.replacementStatus ??
      currentStage.serviceOrder?.reconnectionStatus,
    [currentStage.serviceOrder],
  );

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          Ticket Progress
        </h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>
            <span className="font-medium text-gray-900">
              {displayUserName(currentStage.user)}
            </span>{" "}
            {parse(
              lodash.template(actionsMap[currentStage.stage])(currentStage),
            )}{" "}
            {currentStage.escalatedTo && (
              <span className="font-medium text-gray-900">
                {displayUserName(currentStage.escalatedTo)}
              </span>
            )}
          </p>
        </div>
        {currentStage.serviceOrder && (
          <div className="mt-5 space-y-3">
            <div className="rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-center space-x-6">
              <div className="flex-shrink-0">
                <span
                  className={classNames(
                    "bg-gray-400",
                    "flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white",
                  )}
                >
                  <UserIcon aria-hidden="true" className="h-5 w-5 text-white" />
                </span>
              </div>
              <div className="flex-1">
                <div className="text-sm font-medium text-gray-900 flex">
                  #{currentStage.serviceOrder.code}
                  <span aria-hidden="true" className="hidden sm:mx-2 sm:inline">
                    &middot;
                  </span>
                  <div className="text-gray-600">{status}</div>
                </div>
                <div className="mt-1 text-sm text-gray-600 sm:flex sm:items-center">
                  <div className="mt-1 sm:mt-0">
                    Last updated on{" "}
                    {moment(currentStage.serviceOrder.updatedAt).format(
                      "DD/MM/YYYY [at] hh:mm A",
                    )}
                  </div>
                </div>
              </div>
              <div className="flex-shrink-0">
                <button
                  type="button"
                  onClick={wrapClick(() => {
                    setViewOrderOpen(true);
                  })}
                  className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  View
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {currentStage.serviceOrder && (
        <ServiceOrderContainer
          open={viewOrderOpen}
          close={() => {
            setViewOrderOpen(false);
          }}
          serviceOrderType={currentStage.serviceOrderType}
          serviceOrder={currentStage.serviceOrder}
        />
      )}
    </div>
  );
};

interface FlowContainerProps {
  complaint?: Complaint;
  refetch: () => void;
}

const FlowContainer: FC<FlowContainerProps> = ({ complaint, refetch }): any => {
  const currentUser = useReactiveVar(currentUserVar);
  const isAssignedToMe =
    currentUser?._id === complaint?.currentStage?.user?._id;
  const [stage, setStage] = useState(complaint?.currentStage.stage || "Start");

  const [closeComplaint, { loading: loadingCloseComplaint }] = useMutation(
    CLOSE_COMPLAINT,
    {
      onCompleted: () => {
        refetch();
      },
    },
  );
  const [escalateComplaint, { loading: loadingEscalateComplaint }] =
    useMutation(ESCALATE_COMPLAINT, {
      onCompleted: () => {
        refetch();
      },
    });
  const [updateComplaintStage, { loading: loadingUpdateComplaintStage }] =
    useMutation(UPDATE_COMPLAINT_STAGE, {
      onCompleted: () => {
        refetch();
      },
    });
  const [
    createComplaintServiceOrder,
    { loading: loadingCreateComplaintServiceOrder },
  ] = useMutation(CREATE_COMPLAINT_SERVICE_ORDER, {
    onCompleted: () => {
      refetch();
    },
  });
  const [
    completeComplaintServiceOrder,
    { loading: loadingCompleteComplaintServiceOrder },
  ] = useMutation(COMPLETE_COMPLAINT_SERVICE_ORDER, {
    onCompleted: () => {
      refetch();
    },
  });

  const loading = useMemo(
    () =>
      loadingCloseComplaint ||
      loadingCreateComplaintServiceOrder ||
      loadingEscalateComplaint ||
      loadingUpdateComplaintStage ||
      loadingCompleteComplaintServiceOrder,
    [
      loadingCloseComplaint,
      loadingCreateComplaintServiceOrder,
      loadingEscalateComplaint,
      loadingUpdateComplaintStage,
      loadingCompleteComplaintServiceOrder,
    ],
  );

  if (complaint) {
    if (!isAssignedToMe) {
      const lastStage = lodash.last(complaint.stages);
      if (!lastStage) return null;
      return <ProgressCard currentStage={lastStage} />;
    }
    switch (stage) {
      case "Start":
        return (
          <QuestionCard
            title="Manage Ticket"
            question="Are you capable of resolving this issue?"
            options={[
              { label: "Yes, I can", value: "ExtraWork" },
              { label: "No, I cannot", value: "Escalate" },
            ]}
            onSelect={async (option) => {
              await updateComplaintStage({
                variables: { id: complaint._id, stage: option },
              });
              setStage(option);
            }}
            loading={loading}
          />
        );

      case "Escalate":
        return (
          <EscalateCard
            title="Escalate This Ticket"
            question="Enter name or ID of a staff who can resolve this issue."
            onSelect={async (option) => {
              await escalateComplaint({
                variables: { id: complaint._id, user: option },
              });
              setStage("Escalated");
            }}
            loading={loading}
          />
        );

      case "ExtraWorkNo":
      case "ExtraWork":
        return (
          <NotesCard
            key={"ExtraWork"}
            title="Manage Ticket"
            question="Does the resolution require extra work?"
            options={[
              { label: "No, It does not", value: "ExtraWorkNo" },
              {
                label: "Yes, It requires field work in ZEUS",
                value: "ZeusWork",
              },
              { label: "Yes, It requires work in Hubtel", value: "HubtelWork" },
              { label: "Yes, It requires work in Adora", value: "AdoraWork" },
            ]}
            selectedOption={stage === "ExtraWorkNo" ? "ExtraWorkNo" : undefined}
            onSelect={async (option) => {
              if (!["ExtraWorkNo", "ZeusWork"].includes(option)) {
                await updateComplaintStage({
                  variables: { id: complaint._id, stage: option },
                });
              }
              setStage(option);
            }}
            onSubmit={async (value) => {
              await closeComplaint({
                variables: {
                  id: complaint._id,
                  notes: value.notes,
                  supportingDocumentUrls: value.supportingDocumentUrls,
                },
              });
              setStage("Completed");
            }}
            notesQuestion={
              "Provide summary of all actions performed to close this ticket"
            }
            submitLabel="Close Ticket"
            loading={loading}
          />
        );

      case "HubtelWorkDone":
      case "HubtelWork":
        return (
          <NotesCard
            key={"HubtelWork"}
            title="Manage Ticket"
            question="Are you done with the work in Hubtel"
            options={[{ label: "Yes, I am done", value: "HubtelWorkDone" }]}
            selectedOption={
              stage === "HubtelWorkDone" ? "HubtelWorkDone" : undefined
            }
            onSelect={async (option) => {
              setStage(option);
            }}
            onSubmit={async (value) => {
              console.log(value);
              await updateComplaintStage({
                variables: {
                  id: complaint._id,
                  notes: value.notes,
                  supportingDocumentUrls: value.supportingDocumentUrls,
                  stage,
                },
              });
              setStage("ExtraWork");
            }}
            notesQuestion="What exactly did you do in Hubtel?"
            loading={loading}
          />
        );

      case "AdoraWorkDone":
      case "AdoraWork":
        return (
          <NotesCard
            key={"AdoraWork"}
            title="Manage Ticket"
            question="Are you done with the work in Adora"
            options={[{ label: "Yes, I am done", value: "AdoraWorkDone" }]}
            selectedOption={
              stage === "AdoraWorkDone" ? "AdoraWorkDone" : undefined
            }
            onSelect={(option) => {
              setStage(option);
            }}
            onSubmit={async (value) => {
              await updateComplaintStage({
                variables: {
                  id: complaint._id,
                  notes: value.notes,
                  supportingDocumentUrls: value.supportingDocumentUrls,
                  stage,
                },
              });
              setStage("ExtraWork");
            }}
            notesQuestion="What exactly did you do in Adora?"
            loading={loading}
          />
        );

      case "ZeusWork":
        return (
          <OptionsCard
            title="Manage Ticket"
            question="What field work is required on ZEUS?"
            options={[
              {
                label: "Fault Service Order",
                description: "This is a description",
                value: "FaultServiceOrder",
              },
              {
                label: "Investigation Service Order",
                description: "This is a description",
                value: "InvestigationServiceOrder",
              },
              {
                label: "Replacement Service Order",
                description: "This is a description",
                value: "ReplacementServiceOrder",
              },
              {
                label: "Reconnection Service Order",
                description: "This is a description",
                value: "ReconnectionServiceOrder",
              },
              {
                label: "Disconnection Service Order",
                description: "This is a description",
                value: "DisconnectionServiceOrder",
              },
              {
                label: "Termination Service Order",
                description: "This is a description",
                value: "TerminationServiceOrder",
              },
            ]}
            onSelect={async (option) => {
              await createComplaintServiceOrder({
                variables: {
                  id: complaint._id,
                  serviceOrderType: option,
                  notes: "",
                },
              });
              setStage("ServiceOrder");
            }}
            loading={loading}
          />
        );

      case "ServiceOrder":
        return (
          <ServiceOrderCard
            title="Manage Ticket"
            onSelect={(option) => {
              setStage(option);
            }}
            onSubmit={async (value) => {
              await completeComplaintServiceOrder({
                variables: {
                  id: complaint._id,
                  notes: value.notes,
                  supportingDocumentUrls: value.supportingDocumentUrls,
                },
              });
              setStage("ExtraWork");
            }}
            notesQuestion={
              "Provide details of actions performed during service order?"
            }
            loading={loading}
            currentStage={complaint?.currentStage}
          />
        );

      default:
        return (
          <QuestionCard
            title="Manage Ticket"
            question="Are you capable of resolving this issue?"
            options={[
              { label: "Yes, I can", value: "ExtraWork" },
              { label: "No, I cannot", value: "Escalate" },
            ]}
            onSelect={async (option) => {
              await updateComplaintStage({
                variables: { id: complaint._id, stage: option },
              });
              setStage(option);
            }}
            loading={loading}
          />
        );
    }
  } else return "Nothing";
};

export default FlowContainer;
