
import { Avatar, OfficeCalendarHeader } from 'components';
import _ from 'lodash';
import { FC } from 'react';
import { classNames } from 'utils';
import TicketsOverviewContainer from './containers/tickets-overview';


function UserOverview({ fullName, roleName, tickets}: { 
  fullName: string,
  roleName: string,
  tickets: {
    inProgress: number,
    open: number,
    highPriority: number,
  }
}){

  const colorMap  = {
    inProgress: "bg-primary-900",
    open: "bg-blue-300",
    highPriority: "bg-red-600",
  }

  return (
    <>
      <div className='rounded-lg bg-white shadow'>
        <div className='flex gap-4 sm:gap-6 items-center px-4 py-2 sm:px-6 sm:py-4 border-b-[1px] border-gray-200'>
          <Avatar alt={fullName} />
          <div>
            <p className='text-primary-700 text-sm font-medium'>{fullName}</p>
            <p className='text-xs text-gray-500'>{roleName}</p>
          </div>
        </div>
        <div className=' px-4 py-2 sm:px-6 sm:py-4 flex flex-col gap-3'>
          {
            _.keys(tickets).map((key: any, idx) => (
              <div
                className='flex justify-between items-center gap-2' 
                key={idx}>
                  <div className='flex gap-2 items-center'>
                    <span className={ 
                      classNames('w-2 h-2 rounded-full', 
                      // @ts-ignore
                      colorMap[key]
                    )}></span>
                    <p className='text-sm'>{_.startCase(key)}</p>
                  </div>
                  <span className='text-sm text-gray-500'>{_.get(tickets, key)}</span>
              </div>
            ))
          }
        </div>
      </div>
    </>
  )

}

const DashboardPage: FC = () => {


  const sampleUsers = [
    {
      fullName: "Sam",
      roleName: "IT Support Engineer",
      tickets: {
        inProgress: 4,
        open: 2,
        highPriority: 5
      }
    },
    {
      fullName: "Jack",
      roleName: "IT Support Engineer",
      tickets: {
        inProgress: 4,
        open: 2,
        highPriority: 5
      }
    },
    {
      fullName: "Sarah",
      roleName: "IT Support Engineer",
      tickets: {
        inProgress: 4,
        open: 2,
        highPriority: 5
      }
    },
    {
      fullName: "Mark",
      roleName: "IT Support Engineer",
      tickets: {
        inProgress: 4,
        open: 2,
        highPriority: 5
      }
    },
    {
      fullName: "Megan",
      roleName: "IT Support Engineer",
      tickets: {
        inProgress: 4,
        open: 2,
        highPriority: 5
      }
    },
    {
      fullName: "Akua",
      roleName: "IT Support Engineer",
      tickets: {
        inProgress: 4,
        open: 2,
        highPriority: 5
      }
    },

  ]

  return (
    
    <main className="flex-1 flex flex-col overflow-hidden h-screen bg-gray-50">
      <OfficeCalendarHeader
        renderActions={() => (
          <>
          </>
        )}
      />
      <div className='flex flex-1 overflow-y-auto'>
        <div className="flex-1 min-h-full mx-auto  p-4 sm:p-6 overflow-y-auto bg-gray-50">

          <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-12 lg:gap-8">
              <TicketsOverviewContainer />            
            {
              sampleUsers.map((user, idx) => (
                <div 
                  key={idx}
                  className='col-span-4'
                >
                  <UserOverview
                  {...user}
                  />

                </div>
              ))
            }
            

          </div>
        </div>
      </div>
    </main>
  )
}

export default DashboardPage;
