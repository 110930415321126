import { gql, useMutation } from "@apollo/client";
import { Modal } from "components";
import { useFormik } from "formik";
// import { useEffect } from "react";
import toast from "react-hot-toast";
import TicketForm from "../customer/forms/create-ticket";
import { TicketFormSchema } from "../customer/forms/create-ticket/schema";
import { useNavigate } from "react-location";
import { LocationGenerics } from "router/location";

const CREATE_COMPLAINT = gql`
  mutation CreateComplaint(
    $type: ID!
    $description: String
    $supportingDocumentUrls: [String]
    $servicePoint: ID
    $region: ID
    $district: ID
    $meta: ComplaintMetaInput
    $contactPerson: ContactPersonInput
    $source: String
    $sourceId: String
    $assignToSelf: Boolean
  ) {
    createComplaint(
      type: $type
      description: $description
      supportingDocumentUrls: $supportingDocumentUrls
      servicePoint: $servicePoint
      region: $region
      district: $district
      meta: $meta
      contactPerson: $contactPerson
      source: $source
      sourceId: $sourceId
      assignToSelf: $assignToSelf
    ) {
      _id
    }
  }
`;

export default function CreateComplaintContainer({
  open,
  setOpen,
  refetch,
  id,
}: {
  id?: string;
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const navigate = useNavigate<LocationGenerics>()
  const [createComplaint] = useMutation(CREATE_COMPLAINT, {
    refetchQueries: ["GetServicePointComplaints"]
  });

  const form = useFormik<any>({
    initialValues: {
      contactPersonInfo: {
        fullName: "",
        phoneNumber: "",
        emailAddress: "",
      },
      complaintInfo: {
        category: {
          "_id": "65292342758f1ad6d0fb4736",
          "code": "CC07",
          "type": "ComplaintType",
          "name": "General"
        },
        type: null,
        description: "",
        supportingDocumentUrls: [],
        assignToSelf: false,
        source: "",
        sourceId: ""
      },
    },
    validationSchema: TicketFormSchema,
    onSubmit: async (values) => {
      await createComplaint({
        variables: {
          ...values.complaintInfo,
          contactPerson: values.contactPersonInfo,
          type: values.complaintInfo.type?._id,
        },
      }).then(({ data }) => {
        if (data.createComplaint._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Complaint Created Successfully",
            })
          );
          refetch?.();
          form.resetForm();
          if(values.complaintInfo?.assignToSelf) {
            navigate({to: `/tickets/${data.createComplaint._id}`})
          }
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not create Complaint",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      size={"4xl"}
      title="Add New General Ticket"
      description={`Create a new general ticket.`}
      hidePadding={true}
      renderActions={() => (
        <button
          type="button"
          disabled={form.isSubmitting}
          onClick={form.submitForm}
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:bg-gray-500 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          {form.isSubmitting ? "Creating..." : "Create Ticket"}
        </button>
      )}
    >
      <TicketForm form={form} type="General" />
    </Modal>
  );
}
