import { gql, useQuery } from "@apollo/client";
import { Disclosure } from "@headlessui/react";
import {
  BoltIcon,
  ChatBubbleLeftRightIcon,
  ChatBubbleOvalLeftIcon,
  DevicePhoneMobileIcon,
} from "@heroicons/react/24/outline";
import { ImageView, Loader, MapView } from "components";
import lodash from "lodash";
import moment from "moment";
import numeral from "numeral";
import { classNames } from "utils";

const GET_READING_HISTORY = gql`
  query GetServicePointReadings(
    $servicePoint: ID
    $page: Int
    $pageSize: Int
    $sort: String
  ) {
    readings: getCustomerReadings(
      servicePoint: $servicePoint
      page: $page
      pageSize: $pageSize
      sort: $sort
    ) {
      _id
      currentReading {
        readingDate
        readingValue
      }
      origin
      meta {
        readingImageUrl
        readingLocation {
          coordinates
        }
      }
      meter {
        code
        modelMeta {
          modelCode
          modelName
          brandCode
          brandName
        }
      }
      contractStatus
      contactPerson {
        fullName
      }
      recordedBy {
        _id
        lastName
        firstName
        phoneNumber
      }
      createdAt
    }
  }
`;


interface ServicePointReadingHistoryContainerProps {
  servicePoint: string;
}

export const ServicePointReadingHistoryContainer = ({
  servicePoint,
}:ServicePointReadingHistoryContainerProps) => {
  const { loading, data } = useQuery(GET_READING_HISTORY, {
    notifyOnNetworkStatusChange: false,
    variables: {
      servicePoint,
      sort: "-createdAt",
      page: 1,
      pageSize: 10,
    },
  });

  const actionIconMap = {
    PowerApp: (
      <span
        className={classNames(
          "bg-green-500",
          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
        )}
      >
        <DevicePhoneMobileIcon
          className="h-5 w-5 text-white"
          aria-hidden="true"
        />
      </span>
    ),
    WhatsApp: (
      <span
        className={classNames(
          "bg-green-500",
          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
        )}
      >
        <ChatBubbleOvalLeftIcon
          className="h-5 w-5 text-white"
          aria-hidden="true"
        />
      </span>
    ),
    ZeusApp: (
      <span
        className={classNames(
          "bg-green-500",
          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
        )}
      >
        <BoltIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    SupportApp: (
      <span
        className={classNames(
          "bg-green-500",
          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
        )}
      >
        <ChatBubbleLeftRightIcon
          className="h-5 w-5 text-white"
          aria-hidden="true"
        />
      </span>
    ),
  };

  if(loading) {
    <div className="flex min-h-[65vh] items-center justify-center">
      <Loader />
    </div>
  }

  return data?.readings?.length > 0 ? (
    <div className="flow-root  min-h-[65vh]">
      <ul className="-mb-8">
        {data?.readings?.map((historyItem: any, historyItemIdx: number) => (
          <li key={historyItemIdx}>
            <div className="relative pb-8">
              {historyItemIdx !== data?.readings.length - 1 ? (
                <span
                  className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                  aria-hidden="true"
                />
              ) : null}
              <div className="relative flex space-x-3">
                <div>{lodash.get(actionIconMap, historyItem?.origin, "")}</div>
                <div className="flex min-w-0 flex-1 justify-between space-x-12 pt-1.5">
                  <div className="flex-1">
                    <HistoryDetails history={historyItem} />
                  </div>
                  <div className="whitespace-nowrap text-right text-sm flex flex-col text-gray-500">
                    <time dateTime={historyItem?.createdAt}>
                      {moment(historyItem?.createdAt).format("DD/MM/YYYY")}
                    </time>
                    <time dateTime={historyItem?.createdAt}>
                      {moment(historyItem?.createdAt).format("hh:mm A")}
                    </time>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  ) : (
    <div className="flex min-h-[65vh] items-center justify-center">
      <div className="text-center">
        <svg
          className="mx-auto h-12 w-12 text-gray-400 dark:text-gray-300"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
          />
        </svg>
        <h3 className="mt-2 text-sm font-medium text-gray-900 dark:text-gray-100">
          No Reading Data yet
        </h3>
        <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
          Kindly Refer To CMS For Reading History.
        </p>
      </div>
    </div>
  );
};

const HistoryDetails = ({ history }: { history: any }) => {
  return (
    <>
      <div className="text-sm text-gray-500">
        <span className="font-medium text-gray-900">
          {history?.recordedBy
            ? lodash.startCase(
                [history?.recordedBy?.lastName, history?.recordedBy?.firstName]
                  .join(" ")
                  .toLowerCase()
              )
            : history?.contactPerson?.fullName}
        </span>{" "}
        submitted reading through {lodash.startCase(history.origin)}
        <Disclosure
          defaultOpen
          as="div"
          className="relative p-4 border border-dashed grid-cols-4 border-gray-300 rounded mt-6"
        >
          <Disclosure.Button className="absolute bg-white px-2 -top-2 text-xs">
            Details
          </Disclosure.Button>
          <Disclosure.Panel className="grid mt-1 grid-cols-2 gap-4">
            <div className="col-span-2">
              <div className="grid grid-cols-4 gap-4">
                <div>
                  <span className="block text-xs font-light">Reading Date</span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {history?.currentReading?.readingDate
                      ? moment(history.currentReading.readingDate).format(
                          "DD/MM/YYYY hh:mm A"
                        )
                      : "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-xs font-light">
                    Reading Value
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {history?.currentReading?.readingValue
                      ? `${numeral(
                          history.currentReading.readingValue || 0
                        ).format("0,0")} KWh`
                      : "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-xs font-light">Meter Number</span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {history?.meter?.code || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-xs font-light">Meter Model</span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {history?.meter?.modelMeta?.modelName || "N/A"}
                  </div>
                </div>
                <div className="col-span-2">
                  <span className="text-xs font-light mb-1">Reading Image</span>
                  <div className="mt-1 block w-full sm:text-sm">
                    <ImageView url={history?.meta?.readingImageUrl} />
                  </div>
                </div>
                <div className="col-span-2">
                  <span className="text-xs font-light mb-1">
                    Reading Location
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    <MapView
                      latitude={
                        history?.meta?.readingLocation?.coordinates[1] || 0
                      }
                      longitude={
                        history?.meta?.readingLocation?.coordinates[0] || 0
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </Disclosure.Panel>
        </Disclosure>
      </div>
      {history?.description && (
        <div className="relative p-4 border border-gray-300 rounded mt-6">
          <span className="absolute bg-white px-2 -top-2 text-xs">Notes</span>
          <p className="text-sm text-gray-600 font-light">
            {history.description}
          </p>
        </div>
      )}
    </>
  );
};
