import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import "assets/css/wrap-image.css";

const wrapImage = (img: JSX.Element, empty?: boolean) => {
  if (empty) {
    return (
      <div className='border-2 border-dashed border-gray-300 rounded-md mt-2 h-64 items-center justify-center flex text-gray-600'>
        Dcoument/Image not provided
      </div>
    );
  }
  return <Zoom classDialog='custom-zoom'  >{img}</Zoom>;
};

export default wrapImage;
