import { useQuery, useReactiveVar } from "@apollo/client";
import { TicketIcon } from "@heroicons/react/24/outline";
import { currentConfigVar } from "apollo/cache/config";
import { Shimmers, TableComponent } from "components";
import config from "config";
import { EnquiryTypePicker, DistrictPicker, RegionPicker } from "containers";
import lodash from "lodash";
import moment from "moment";
import { FC, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import {
  dispatchModal,
  displayUserName,
  useTableData,
  useUrlState,
  wrapClick,
} from "utils";
import { GET_ENQUIRIES } from "./apollo";
import CreateEnquiryContainer from "./create";
import ViewEnquiryContainer from "./view";

const EnquiriesPage: FC = () => {
  const { dateFormat } = useReactiveVar(currentConfigVar);
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [region, setRegion] = useUrlState("region");
  const [district, setDistrict] = useUrlState("district");
  const [type, setType] = useUrlState<string | undefined>("type");
  const [createOpen, setCreateOpen] = useState(false);

  const filter = useMemo(
    () => ({
      page: Number(searchParams.page || config.constants.page),
      pageSize: Number(searchParams.pageSize || config.constants.pageSize),
      search: searchParams.search || undefined,
      searchFields: [
        "code",
        "contactPerson.fullName",
        "contactPerson.phoneNumber",
      ],
      sort: searchParams.sort || "",
      fromDate: searchParams.fromDate || undefined,
      toDate: searchParams.toDate || undefined,
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      type,
    }),
    [searchParams, type],
  );

  const { data, loading, refetch, networkStatus } = useQuery(GET_ENQUIRIES, {
    variables: filter,
    notifyOnNetworkStatusChange: true,
  });

  const records = useTableData(data);

  const onSearch = (searchValue: string) => {
    navigate({
      search: (old) => ({
        ...old,
        page: config.constants.page,
        pageSize: searchParams.pageSize || config.constants.pageSize,
        search: String(searchValue),
      }),
    });
  };

  useEffect(() => {
    navigate({
      search: (old) => ({
        // ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? {} : { search: "" }),
        ...(!old?.sort ? { sort: "-createdAt" } : {}),
      }),
    });
  }, [navigate]);

  return (
    <main className="flex-1 flex flex-row overflow-hidden bg-white divide-x divide-gray-200">
      <div className="flex flex-1 flex-col overflow-hidden divide-y divide-gray-200">
        <div className="h-20 px-6 flex-shrink-0 flex justify-between items-center">
          <h3 className="text-lg font-medium text-gray-900">Enquiries</h3>

          <div className="">
            <button
              type="button"
              onClick={wrapClick(() => setCreateOpen(true))}
              className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-primary-50 hover:ring-primary-600 group"
            >
              <TicketIcon
                aria-hidden="true"
                className="-ml-0.5 h-5 w-5 text-gray-400 group-hover:text-primary-600"
              />
              Create Enquiry
            </button>
          </div>
        </div>
        <div className="flex flex-1 overflow-y-auto">
          <div className="flex-1 min-h-full mx-auto min-w-0 py-4 overflow-hidden overflow-y-auto light flex">
            <TableComponent
              title={"enquiries"}
              refetch={refetch}
              isRefetching={loading && networkStatus === 4}
              loading={loading}
              data={records}
              hasSearch={true}
              onSearchClicked={onSearch}
              renderFilter={() => (
                <div className="grid grid-cols-5 gap-6 p-6 bg-primary-100/60 rounded-md my-3">
                  <div className="flex flex-col gap-1">
                    <RegionPicker
                      id="region"
                      label="Region"
                      setFieldTouched={() => { }}
                      setFieldError={() => { }}
                      setFieldValue={(_field: string, value: string) => {
                        setRegion(value || "");
                        setDistrict("");
                      }}
                      values={{ region }}
                      rawId={true}
                    />
                  </div>

                  <div className="flex flex-col gap-1">
                    <DistrictPicker
                      id="district"
                      label="District"
                      setFieldTouched={() => { }}
                      setFieldError={() => { }}
                      setFieldValue={(_field: string, value: string) =>
                        setDistrict(value || "")
                      }
                      values={{ district }}
                      filter={{ region }}
                      rawId={true}
                      disabled={!region}
                    />
                  </div>
                  <div className="flex flex-col gap-1 col-span-2">
                    <EnquiryTypePicker
                      id={"type"}
                      label="Enquiry Type"
                      values={{ type }}
                      rawId
                      setFieldValue={(_: any, value: string) => {
                        setType(value ?? undefined);
                      }}
                    />
                  </div>
                </div>
              )}
              renderColumns={() => (
                <tr>
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-3 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Enquiry # | Date
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Contact Person
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Source | Type
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Created By
                  </th>
                </tr>
              )}
              renderLoader={() => (
                <tr>
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.DoubleShimmer />
                  </td>
                  <td className="px-6 py-4 w-4 border-b border-gray-200">
                    <Shimmers.AvatarShimmer />
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.DoubleShimmer />
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.DoubleShimmer />
                  </td>
                </tr>
              )}
              renderItem={(item) => (
                <tr
                  key={item._id}
                  className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
                  onClick={wrapClick(dispatchModal(item._id, "view"))}
                >
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                    <div className="font-medium text-gray-900 dark:text-gray-100">
                      {item?.code || "N/A"}
                    </div>
                    <div className="font-medium text-gray-500 dark:text-gray-400">
                      {moment(item?.createdAt).format(dateFormat)}
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                    <div className="text-gray-900 dark:text-gray-100">
                      {item?.contactPerson?.fullName || "N/A"}
                    </div>
                    <div className="text-gray-500 dark:text-gray-500">
                      {item?.contactPerson?.phoneNumber || "N/A"}
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                    <div className="text-gray-900 dark:text-gray-100">
                      {lodash.startCase(item?.source) || "N/A"}
                    </div>
                    <div className="text-gray-500 dark:text-gray-500">
                      {item?.type?.name || "N/A"}
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                    <div className="text-gray-900 dark:text-gray-100">
                      {displayUserName(item?.createdBy) || "N/A"}
                    </div>
                    <div className="text-gray-500 dark:text-gray-500">
                      {item?.createdBy?.code || "N/A"}
                    </div>
                  </td>
                </tr>
              )}
            />
          </div>
        </div>
      </div>

      <CreateEnquiryContainer
        open={createOpen}
        setOpen={setCreateOpen}
        refetch={refetch}
      />
      {!!searchParams.id?.length && (
        <>
          <ViewEnquiryContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
        </>
      )}
    </main>
  );
};

export default EnquiriesPage;
