import { useMutation } from "@apollo/client";
import { Modal } from "components";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import EnquiryForm from "./forms/create-enquiry";
import { EnquiryFormSchema } from "./forms/create-enquiry";
import { CREATE_ENQUIRY } from "./apollo";

export default function CreateEnquiryContainer({
  open,
  setOpen,
  refetch,
  id,
}: {
  id?: string;
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const [createEnquiry] = useMutation(CREATE_ENQUIRY);

  const form = useFormik<any>({
    initialValues: {
      contactPersonInfo: {
        fullName: "",
        phoneNumber: "",
        emailAddress: "",
      },
      enquiryInfo: {
        type: null,
        description: "",
        source: "",
        sourceId: "",
      },
    },
    validationSchema: EnquiryFormSchema,
    onSubmit: async (values) => {
      await createEnquiry({
        variables: {
          ...values.enquiryInfo,
          contactPerson: values.contactPersonInfo,
          type: values.enquiryInfo.type?._id,
        },
      }).then(({ data }) => {
        if (data.createEnquiry._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Enquiry Created Successfully",
            }),
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not create Enquiry",
            }),
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      size={"4xl"}
      title="Add New Enquiry"
      description={`Create a new enquiry.`}
      hidePadding={true}
      renderActions={() => (
        <button
          type="button"
          disabled={form.isSubmitting}
          onClick={form.submitForm}
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:bg-gray-500 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          {form.isSubmitting ? "Creating..." : "Create Enquiry"}
        </button>
      )}
    >
      <EnquiryForm form={form} />
    </Modal>
  );
}
