import _ from "lodash";
import { FC } from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { classNames } from "utils";

export interface RichInputProps {
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  values: any;
  handleChange: any;
  setFieldTouched?: any;
  errors?: any;
  touched?: any;
  labelHidden?: boolean;
}

export const RichInput: FC<RichInputProps> = ({
  id,
  disabled,
  required,
  values,
  handleChange,
  setFieldTouched,
  placeholder,
  label,
  errors,
  touched,
  labelHidden,
}) => {
  return (
    <div className="mb-0">
      {!labelHidden && (
        <label className="block text-sm pb-1 font-medium text-gray-700">
          {label}
          {required && <span className={`text-red-700`}>*</span>}
        </label>
      )}
      <div>
        <ReactQuill
          onChange={(event) => {
            handleChange({
              target: {
                name: id,
                value: event,
              },
            });
            setFieldTouched(id, true)
          }}
          id={id}
          placeholder={placeholder}
          onBlur={()=> {}}
          value={_.get(values, id, "")}
          className={classNames(labelHidden ? "" : "mt-1 h-48 mb-[52px]")}
          theme="snow"
        />
      </div>
      {_.get(errors, id) && _.get(touched, id) ? (
        <p className=" text-sm text-red-600" id={`${id}-error`}>
          {_.get(errors, id)}
        </p>
      ) : null}
    </div>
  );
};

export default RichInput;
