import { Description, Field, Label, Switch } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { RichInput, SelectInput, TextInput, UploadBox } from "components";
import {
  ComplaintCategoryPicker,
  ComplaintTypePicker,
  DistrictPicker,
  RegionPicker,
} from "containers";
import { FormikProps } from "formik";
import parse from "html-react-parser";
import { FC } from "react";
import { classNames } from "utils";

type Props = {
  form: FormikProps<any>;
  type?: "General" | "Specific";
};

export const TicketForm: FC<Props> = ({ form, type }) => {
  return (
    <div className="space-y-6 divide-y no-scrollbar divide-gray-200 flex-1 overflow-y-auto p-6">
      <div>
        <span className="text-xs font-light">Contact Person Information</span>
        <div className="grid grid-cols-3 gap-6 mt-2">
          <div className="col-span-3">
            <TextInput
              id="contactPersonInfo.fullName"
              label="Full Name"
              type="text"
              required
              placeholder="e.g. Emmanuel Baidoo"
              {...form}
            />
          </div>

          <div className="col-span-2">
            <TextInput
              id="contactPersonInfo.emailAddress"
              label="Email Address"
              type="text"
              placeholder="e.g. ebaidoo@office.com"
              {...form}
            />
          </div>
          <div className="">
            <TextInput
              id="contactPersonInfo.phoneNumber"
              label="Phone Number"
              type="text"
              placeholder="e.g. 0506339153"
              required
              {...form}
          />
          </div>
          <div className="space-y-1">
            <RegionPicker
              id="complaintInfo.region"
              label="Region"
              required
              {...form}
              rawId
              disabled={type !== "General"}
            />
          </div>
          <div>
            <DistrictPicker
              id="complaintInfo.district"
              label="District"
              required
              disabled={
                !form.values?.complaintInfo?.region || type !== "General"
              }
              filter={{ region: form.values?.complaintInfo?.region }}
              {...form}
              rawId
            />
          </div>
          <div className="">
            <TextInput
              id="complaintInfo.ghanaPostAddress"
              label="Ghana Post Address"
              type="text"
              placeholder="e.g. GA-241-3431"
              {...form}
            />
          </div>
          <div className="col-span-2">
            <TextInput
              id="complaintInfo.location"
              label="Location"
              type="text"
              placeholder="e.g. Tema Pig Farm"
              {...form}
            />
          </div>
          <div className="">
            <TextInput
              id="complaintInfo.poleNumber"
              label="Pole Number"
              type="text"
              placeholder="e.g. GA-241-3431"
              {...form}
            />
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Complaint Type Information</span>
        <div className="grid grid-cols-2 gap-6 mt-2">
          <div className="space-y-1">
            <ComplaintCategoryPicker
              id="complaintInfo.category"
              label="Complaint Category"
              required
              {...form}
              disabled={type === "General"}
            />
          </div>
          <div>
            <ComplaintTypePicker
              id="complaintInfo.type"
              label="Complaint Type"
              required
              disabled={!form.values?.complaintInfo?.category}
              filter={{ category: form.values?.complaintInfo?.category?.name }}
              {...form}
            />
          </div>
          {form.values.complaintInfo?.type?.description && (
            <div className="rounded-md bg-yellow-50 p-4 col-span-2">
              <div className="flex">
                <div className="flex-shrink-0">
                  <InformationCircleIcon
                    className="h-5 w-5 text-yellow-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <div className="text-sm text-yellow-700">
                    <h2 className="font-medium">Info About Complaint Type</h2>
                    <p className="mt-1">
                      {parse(form.values.complaintInfo?.type?.description)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="col-span-2">
            <RichInput
              id="complaintInfo.description"
              label="Description"
              placeholder="e.g. Connected the cables underground from next door"
              required
              {...form}
            />
          </div>
          <div className="col-span-2">
            <UploadBox
              label="Supporting document"
              required={form.values.complaintInfo?.type?.requiresDocument}
              multiple
              maxFiles={6}
              id={"complaintInfo.supportingDocumentUrls"}
              {...form}
            />
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Extra Information</span>
        <div className="grid grid-cols-3 gap-6 mt-2">
          <div className="space-y-1">
            <SelectInput
              id="complaintInfo.source"
              label="Complaint Source"
              required
              {...form}
              options={[
                { label: "--- Select One ---", value: "" },
                { label: "Email", value: "Email" },
                { label: "Instagram", value: "Instagram" },
                { label: "Messenger", value: "Messenger" },
                { label: "Phone Call", value: "PhoneCall" },
                { label: "SMS", value: "TextMessage" },
                { label: "Telegram", value: "Telegram" },
                { label: "Twitter (X)", value: "Twitter" },
                { label: "Web Portal", value: "WebPortal" },
                { label: "WhatsApp", value: "WhatsApp" },
              ]}
            />
          </div>
          {form.values?.complaintInfo?.source === "PhoneCall" && (
            <div>
              <TextInput
                id="complaintInfo.sourceId"
                label="Contact Id (Presence Id)"
                disabled={!form.values?.complaintInfo?.source}
                {...form}
              />
            </div>
          )}
          <Field as="div">
            <span className="flex flex-grow flex-col">
              <Label
                as="span"
                className="text-sm font-medium text-gray-700"
                passive
              >
                Assign Ticket To Self
              </Label>
            </span>
            <div className="flex items-center justify-between mt-1 space-x-2 h-[38px]">
              <Description
                as="span"
                className="text-xs text-gray-500 line-clamp-2"
              >
                Do you want this ticket to be assigned to yourself?
              </Description>
              <Switch
                checked={form.values?.complaintInfo?.assignToSelf}
                onChange={(val: boolean) =>
                  form.setFieldValue("complaintInfo.assignToSelf", val)
                }
                className={classNames(
                  form.values?.complaintInfo?.assignToSelf
                    ? "bg-primary-600"
                    : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    form.values?.complaintInfo?.assignToSelf
                      ? "translate-x-5"
                      : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </div>
          </Field>
        </div>
      </div>
    </div>
  );
};

export default TicketForm;
