import { FC } from "react";
import { CalendarHeader } from "components";

interface OfficeHeaderProps {
  renderActions?: FC<{}>;
  views?: string[];
  defaultView?: string
  disableRegion?: boolean
}

const OfficeHeader: FC<OfficeHeaderProps> = ({ renderActions, views, defaultView, disableRegion }) => {

  return (
    <CalendarHeader
      renderActions={renderActions}
      views={views}
      defaultView={defaultView}
      disableRegion={disableRegion}
    />
  );
};

export default OfficeHeader;
