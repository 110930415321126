import { gql } from "@apollo/client";

export const GET_ENQUIRIES = gql`
  query GetEnquiries(
    $page: Int
    $pageSize: Int
    $search: String
    $searchFields: [String!]
    $sort: String
    $type: ID
    $region: ID
    $district: ID
    $fromDate: Date
    $toDate: Date
  ) {
    rows: getEnquiries(
      page: $page
      pageSize: $pageSize
      search: $search
      searchFields: $searchFields
      sort: $sort
      type: $type
      region: $region
      district: $district
      fromDate: $fromDate
      toDate: $toDate
    ) {
      _id
      code
      description
      source
      contactPerson {
        emailAddress
        fullName
        phoneNumber
      }
      type {
        _id
        code
        name
      }
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      createdAt
      updatedAt
      createdBy {
        ... on User {
          _id
          code
          firstName
          lastName
          profileImageUrl
        }
        ... on PartnerUser {
          _id
          code
          firstName
          lastName
          profileImageUrl
        }
      }
      createdByType
    }
    count: getEnquiriesCount(
      search: $search
      searchFields: $searchFields
      type: $type
      region: $region
      district: $district
      fromDate: $fromDate
      toDate: $toDate
    )
  }
`;

export const CREATE_ENQUIRY = gql`
  mutation CreateEnquiry(
    $type: ID!
    $description: String
    $region: ID
    $district: ID
    $meta: EnquiryMetaInput
    $contactPerson: ContactPersonInput
    $source: String
    $sourceId: String
  ) {
    createEnquiry(
      type: $type
      description: $description
      region: $region
      district: $district
      meta: $meta
      contactPerson: $contactPerson
      source: $source
      sourceId: $sourceId
    ) {
      _id
    }
  }
`;
