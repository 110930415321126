import { gql } from "@apollo/client";

export const GET_COMPLAINTS = gql`
  query GetComplaints(
    $page: Int
    $pageSize: Int
    $search: String
    $searchFields: [String!]
    $sort: String
    $category: ComplaintCategory
    $type: ID
    $status: ComplaintStatus
    $statuses: [ComplaintStatus]
    $region: ID
    $district: ID
    $fromDate: Date
    $toDate: Date
    $assignedToMe: Boolean
    $overdue: Boolean
  ) {
    rows: getComplaints(
      page: $page
      pageSize: $pageSize
      search: $search
      searchFields: $searchFields
      sort: $sort
      category: $category
      type: $type
      status: $status
      statuses: $statuses
      region: $region
      district: $district
      fromDate: $fromDate
      toDate: $toDate
      assignedToMe: $assignedToMe
      overdue: $overdue
    ) {
      _id
      code
      description
      contactPerson {
        emailAddress
        fullName
        phoneNumber
      }
      category
      type {
        code
        name
      }
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      status
      createdAt
      updatedAt
      createdBy {
        ... on User {
          _id
          code
          firstName
          lastName
          profileImageUrl
        }
        ... on PartnerUser {
          _id
          code
          firstName
          lastName
          profileImageUrl
        }
      }
      createdByType
      currentStage {
        user {
          ... on User {
            _id
            code
            firstName
            lastName
            profileImageUrl
          }
          ... on PartnerUser {
            _id
            code
            firstName
            lastName
            profileImageUrl
          }
        }
        userType
      }
    }
    count: getComplaintsCount(
      search: $search
      searchFields: $searchFields
      category: $category
      type: $type
      status: $status
      statuses: $statuses
      region: $region
      district: $district
      fromDate: $fromDate
      toDate: $toDate
      assignedToMe: $assignedToMe
      overdue: $overdue
    )
  }
`;

export const GET_COMPLAINT_SUMMARY = gql`query GetPartnerComplaintSummary(
  $region: ID
  $district: ID
  $fromDate: Date
  $toDate: Date
  $dateField: String
) {
  summary: getPartnerComplaintSummary(
    region: $region
    district: $district
    fromDate: $fromDate
    toDate: $toDate
    dateField: $dateField
  ) {
    totalTickets
    totalOpenTickets
    totalInProgressTickets
    totalClosedTickets
    totalOverdueTickets
    userTickets
    userOpenTickets
    userInProgressTickets
    userClosedTickets
    userOverdueTickets
  }
}
`;